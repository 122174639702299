import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useChangePassword = () => {
  const path = '/accounts/password/change';

  const { showNotification } = useRoot();
  const { t } = useTranslation(LOCALIZATION_PAGES.CHANGE_PASSWORD);

  const [{ result, loading, error }, changePassword] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
  });
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('password_change_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
      setResponse(false);
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('password_change_success'),
      });
      setResponse(true);
    }
  }, [result, showNotification, t, setResponse]);

  return [{ loading, response }, changePassword];
};

export default useChangePassword;
