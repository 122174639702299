import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import i18n from 'i18next';
import uuid from 'uuid';
import { useImmer } from 'use-immer';
import { useSelector, useDispatch } from 'react-redux';

import { NOTIFICATION_DISMISS_TIMEOUT } from 'utils/constants';
import { getDefaultLanguage, getCurrentLanguageFromStorage } from 'utils/localization/localization';
import { setupApiConfig, removeAuthorization, setupInterceptors, setupLangHeader } from 'utils/apiConfig';
import * as TokenService from 'utils/tokenService';
import { getNotification } from 'redux/reducers/uiReducer';
import { getLanguage } from 'redux/reducers/sessionReducer';
import { sessionActions } from 'redux/actions';
import { useSession, useRefreshToken } from 'utils/hooks/api';
import { getUser } from 'redux/reducers/sessionReducer';
import Loader from 'components/Loader/Loader';
import { CenterContent } from 'components/Layout';

const RootContext = React.createContext();

export const RootProvider = props => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [notifications, setNotifications] = useImmer([]);
  const dispatch = useDispatch();
  const [{ refreshTokenResult, loadingRefreshToken }, getRefreshToken] = useRefreshToken();
  const notification = useSelector(getNotification);
  const language = useSelector(getLanguage);
  const [, getSession] = useSession();
  const user = useSelector(getUser);
  const [languageChangedFlag, setLanguageChangeFlag] = useState('');
  const [isSyncingAccreditations, setIsSyncingAccreditations] = useState(false);

  const clearNotification = useCallback(
    notificationId => {
      setNotifications(draft => {
        const index = draft.findIndex(n => n.id === notificationId);
        const notification = draft[index];
        clearInterval(notification.timeout);
        draft.splice(index, 1);
      });
    },
    [setNotifications]
  );

  const showNotification = useCallback(
    ({ message, title }, variant, duration = NOTIFICATION_DISMISS_TIMEOUT) => {
      const id = uuid();

      // create timeout object for deleting notification when expires
      const timeout = setTimeout(() => {
        clearNotification(id);
      }, duration);

      setNotifications(draft => {
        draft.push({
          message,
          title,
          variant,
          duration,
          id,
          timeout,
        });
      });
    },
    [clearNotification, setNotifications]
  );

  const getRefreshedToken = useCallback(() => {
    const isLoggedIn = TokenService.loggedIn();
    if (isLoggedIn) {
      getRefreshToken();
    }
  }, [getRefreshToken]);

  const initialize = useCallback(async () => {
    // load localization
    let language = getCurrentLanguageFromStorage() || getDefaultLanguage();
    i18n.changeLanguage(language.LANGUAGE_CODE);
    dispatch(sessionActions.setLanguage(language));
    setupInterceptors();

    // Check whether user is logged in and setup stuff accordingly
    const isLoggedIn = TokenService.loggedIn();
    if (isLoggedIn) {
      setupApiConfig();
      getSession();
    } else {
      removeAuthorization();
      setIsInitialized(true);
    }
  }, [dispatch, getSession]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language.LANGUAGE_CODE);
      setupLangHeader(language);
      setLanguageChangeFlag(language?.ISO_CODE);
    }
  }, [language]);

  useEffect(() => {
    if (notification) {
      showNotification({ message: notification.message, title: notification.title }, notification.variant);
    }
  }, [notification, showNotification]);

  useEffect(() => {
    if (user) {
      setIsInitialized(true);
    }
  }, [user]);

  useEffect(() => {
    const intervalId = setInterval(() => getRefreshedToken(), 900000); // 900000 miliseconds = 15 minutes
    return () => clearInterval(intervalId);
  }, [getRefreshedToken]);

  useEffect(() => {
    if (!loadingRefreshToken && refreshTokenResult) {
      TokenService.setToken(refreshTokenResult.idToken);
      TokenService.setAccessToken(refreshTokenResult.accessToken);
      setupApiConfig(refreshTokenResult.idToken);
    }
  }, [loadingRefreshToken, refreshTokenResult]);

  const context = useMemo(
    () => ({
      isInitialized,
      notifications,
      showNotification,
      isSyncingAccreditations,
      setIsSyncingAccreditations,
      languageChangedFlag,
      isLoggingOut,
      setIsLoggingOut,
    }),
    [
      isInitialized,
      notifications,
      showNotification,
      isSyncingAccreditations,
      setIsSyncingAccreditations,
      languageChangedFlag,
      isLoggingOut,
      setIsLoggingOut,
    ]
  );

  if (!isInitialized)
    return (
      <CenterContent>
        <Loader />
      </CenterContent>
    );

  return <RootContext.Provider value={context}>{props.children}</RootContext.Provider>;
};

export function useRoot() {
  const context = useContext(RootContext);
  if (!context) {
    throw new Error(`useRoot must be used within a RootProvider`);
  }
  return context;
}

export const Consumer = RootContext.Consumer;
