import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, API_QUERY } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useUserPurchases = (id, query = API_QUERY.default) => {
  const queryString = `${API_ENDPOINTS.EXTERNAL_USERS}/${id}/purchase-history?Term=${query.term}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}`;

  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);

  const { showNotification } = useRoot();
  const [{ result, loading, error }, getUserPurchases] = useApi(queryString);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('user_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ result, loading, error }, getUserPurchases];
};

export default useUserPurchases;
