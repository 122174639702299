import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useScreeningSale = id => {
  const path = `screenings/${id}/sales`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);

  const { showNotification } = useRoot();
  const [
    { result: screeningSale, loading: loadingScreeningSale, error: errorScreeningSale },
    getScreeningSale,
  ] = useApi(path, { initialFetch: false });

  useEffect(() => {
    if (errorScreeningSale) {
      showNotification(
        {
          message: t('alert_screening_sales_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorScreeningSale, showNotification, t]);

  return [{ screeningSale, loadingScreeningSale, errorScreeningSale }, getScreeningSale];
};

export default useScreeningSale;
