import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ErrorMessage } from 'components/Form';
import { Label } from 'components/Typography';
import DateTimePicker from 'components/DatePicker/DateTimePicker';

const DateTimeInput = ({ label = '', validationMessage, onChange, value, minDate, maxDate, ...rest }) => {
  const onChangeHandler = date => {
    onChange(date);
  };
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <DateTimePicker minDate={minDate} maxDate={maxDate} value={value} onChangeHandler={onChangeHandler} {...rest} />
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </InputWrapper>
  );
};

DateTimeInput.propTypes = {
  width: PropTypes.string || PropTypes.number,
  label: PropTypes.string,
};

const InputWrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

export default DateTimeInput;
