import React from 'react';
import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegCalendar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { LOCALIZATION_PAGES } from 'utils/constants';

import DatePickerToggle from './DatePickerToggle';
import Wrapper from './DatePickerWrapper';

const DateTimePicker = ({
  value = new Date(),
  onChangeHandler,
  minDate,
  maxDate,
  showTimeSelect = true,
  block = false,
  ...rest
}) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const convertDateToString = d => {
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  const DateToggle = React.forwardRef(({ onClick }, ref) => {
    return (
      <DateToggleBtn onClick={onClick} showTimeSelect={showTimeSelect} block={block}>
        <CalendarIcon />
        {convertDateToString(value)}
      </DateToggleBtn>
    );
  });

  const TimeToggle = React.forwardRef(({ onClick }, ref) => {
    return (
      <TimeToggleBtn onClick={onClick}>{`${value.getHours()}:${(value.getMinutes() < 10 ? '0' : '') +
        value.getMinutes()}`}</TimeToggleBtn>
    );
  });

  return (
    <DatePickerWrapper block={block}>
      <DatePicker
        selected={value}
        onChange={date => onChangeHandler(date)}
        customInput={<DateToggle />}
        minDate={minDate}
        maxDate={maxDate}
      />
      {showTimeSelect && (
        <DatePicker
          selected={value}
          onChange={date => onChangeHandler(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption={t('time')}
          timeFormat="HH:mm"
          customInput={<TimeToggle />}
        />
      )}
    </DatePickerWrapper>
  );
};

const DatePickerToggleAdjust = styled(DatePickerToggle)`
  border-radius: 0;
`;

const DateToggleBtn = styled(DatePickerToggleAdjust)`
  ${({ showTimeSelect }) =>
    showTimeSelect
      ? css`
          border-radius: 4px 0 0 4px;
        `
      : css`
          border-radius: 4px;
        `};
  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `};
  height: 38px;
`;

const TimeToggleBtn = styled(DatePickerToggleAdjust)`
  border-radius: 0 4px 4px 0;
  border-left: 0;
  height: 38px;
`;

const CalendarIcon = styled(FaRegCalendar)`
  margin-bottom: 5px;
  margin-right: 1rem;
  opacity: 50%;
`;

const DatePickerWrapper = styled(Wrapper)`
  align-self: flex-start;
  ${({ block }) => {
    return block
      ? css`
          width: 100%;
          & .react-datepicker-wrapper {
            width: 100%;
          }
        `
      : null;
  }};
`;

export default DateTimePicker;
