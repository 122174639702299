import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useVenue = id => {
  const path = `/venues/${id}?details=true`;
  const { t } = useTranslation(LOCALIZATION_PAGES.VENUES);

  const { showNotification } = useRoot();
  const [{ result: venue, loading: loadingVenue, error }, getVenue] = useApi(path, { initialFetch: !!id });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('failed_to_fetch_venue'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ venue, loadingVenue, error }, getVenue];
};

export default useVenue;
