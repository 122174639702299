import { useEffect } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const path = 'roles';

const useCreateRole = role => {
  const { t } = useTranslation(LOCALIZATION_PAGES.ROLES);
  const { showNotification } = useRoot();

  const [{ result: createRoleResult, loading: loadingCreateRole, error }, createRole] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
    data: role,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('alert_create_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (createRoleResult) {
      showNotification({
        message: t('alert_create_success'),
      });
    }
  }, [createRoleResult, showNotification, t]);

  return [{ createRoleResult, loadingCreateRole }, createRole];
};

export default useCreateRole;
