import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const CenterContent = ({ children }) => {
  return (
    <FullHeightContainer fluid>
      <FullHeightRow>
        <XYCenteredCol>{children}</XYCenteredCol>
      </FullHeightRow>
    </FullHeightContainer>
  );
};

const FullHeightContainer = styled(Container)`
  height: 100%;
`;

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const XYCenteredCol = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default CenterContent;
