import { useState, useEffect, useCallback } from 'react';

import { DEFAULT_PAGE_SIZE } from 'utils/constants';

const usePagination = () => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  useEffect(() => {
    if (count => 0) {
      setMaxPages(Math.ceil(count / DEFAULT_PAGE_SIZE));
    }
  }, [count]);

  const onChangePage = useCallback(
    newPage => {
      setPage(newPage);
    },
    [setPage]
  );

  /**
   * page - current page
   * pageSize - number of records per page
   * count - total number of records
   * maxPages - total number of pages
   */
  return [page, count, maxPages, onChangePage, setCount];
};

export default usePagination;
