import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';

const path = id => `${API_ENDPOINTS.BALLOTING_CATEGORIES}/${id}`;

const useBallotingCategory = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.BALLOTING);
  const { showNotification } = useRoot();
  const [{ result: category, loading, error }, getCategory] = useApi(path(id));

  useEffect(() => {
    if (error)
      showNotification(
        {
          message: t('category_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
  }, [error, showNotification, t]);

  return [{ category, loading }, getCategory];
};

export default useBallotingCategory;
