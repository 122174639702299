import React from 'react';
import { CustomInput } from 'reactstrap';
import styled from 'styled-components';

const RadioButton = ({ ...rest }) => {
  return <Container type="radio" {...rest} />;
};

const Container = styled(CustomInput)`
  cursor: pointer;
  & label {
    font-size: 14px;
    line-height: 1.57;
    color: var(--dark);
    cursor: pointer;
  }
`;

export default RadioButton;
