import { useEffect, useCallback, useState } from 'react';
import { useImmer } from 'use-immer';

const useQuery = initialValues => {
  const { page: initialQueryPage, ...initialQuery } = initialValues;
  // apiQuery is changed only after isQueryApplied is true
  const [apiQuery, setApiQuery] = useImmer(initialValues);
  // query and queryPage are separated
  // to prevent unnecessary api calls
  // and to avoid manually reseting the page to 0
  const [query, setQuery] = useImmer(initialQuery);
  const [queryPage, setQueryPage] = useState(initialQueryPage);
  // used as flag to trigger apiQuery update
  const [isQueryApplied, setIsQueryApplied] = useState(false);
  // total number of records
  const [count, setCount] = useState(0);
  // total number of pages
  const [maxPages, setMaxPages] = useState(0);
  //// pageSize - number of records per page

  // pagination method
  const changePage = useCallback(
    newPage => {
      setQueryPage(newPage - 1);
      setIsQueryApplied(true);
    },
    [setQueryPage, setIsQueryApplied]
  );

  // set max pages when count or pagesize change
  useEffect(() => {
    if (count >= 0 && query.pageSize) {
      setMaxPages(Math.ceil(count / query.pageSize));
    }
  }, [count, query.pageSize, setMaxPages]);

  // reset page to 0 on query change
  useEffect(() => {
    setQueryPage(0);
    setIsQueryApplied(true);
  }, [query, setQueryPage, setIsQueryApplied]);

  // apply query to apiQuery on page change (final query change)
  useEffect(() => {
    if (isQueryApplied) {
      setApiQuery(() => ({ ...query, page: queryPage }));
      setIsQueryApplied(false);
    }
  }, [query, queryPage, setApiQuery, setIsQueryApplied, isQueryApplied]);

  return { apiQuery, setQuery, page: queryPage, count, setCount, maxPages, changePage };
};

export default useQuery;
