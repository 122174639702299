import { useState, useEffect, useCallback } from 'react';

import API, { getData } from 'utils/apiConfig';
import { HTTP_VERBS } from 'utils/constants';

/**
 * Custom hook for handling API requests.
 * @param {string} url - API URL endpoint
 * @param {string} verb - GET/POST/PUT/DELETE/PATCH
 * @param {object} data - data to be sent to the API endpoint
 * @param {object} client - API client for handling requests (in case there are multiple clients)
 * @param {bool} initialFetch - indicates whether the API call should be executed immediately
 * @param {string} returnType - expected type of data
 */
const useApi = (
  url,
  { initialFetch = true, client = API, verb = HTTP_VERBS.GET, data = null, returnType = 'json' } = {}
) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(initialFetch);

  const execute = useCallback(
    obj => {
      let route = null;
      switch (verb) {
        case HTTP_VERBS.GET:
          route = client.get(url, { responseType: returnType });
          break;
        case HTTP_VERBS.POST:
          route = client.post(url, obj);
          break;
        case HTTP_VERBS.PUT:
          route = client.put(url, obj);
          break;
        case HTTP_VERBS.PATCH:
          route = client.patch(url, obj);
          break;
        case HTTP_VERBS.DELETE:
          route = client.delete(url);
          break;
        default:
          route = client.get(url);
          break;
      }

      return route;
    },
    [url, verb, client, returnType]
  );

  const fetch = useCallback(
    async obj => {
      setLoading(true);
      try {
        const response = await execute(obj || data);
        const items = getData(response);
        if (items && items instanceof Blob) {
          const headerval = response.headers['content-disposition'];
          let filename = headerval
            .split(';')[1]
            .split('=')[1]
            .replace('"', '')
            .replace('"', '');
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(items);
          link.download = filename;
          link.click();
        }
        if ((items && items !== Object(items)) || items === '') {
          setResult({ result: items });
        } else {
          setResult(items);
        }
        setError(null);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err && err !== Object(err)) {
          setError({ message: err });
        } else {
          setError(err);
        }
      }
    },
    [execute, data]
  );

  useEffect(() => {
    if (initialFetch) {
      fetch();
    }
  }, [url, fetch, initialFetch]);

  return [{ result, loading, error, setResult }, fetch];
};

export default useApi;
