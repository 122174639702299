import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useDeleteTicketType = id => {
  const endpoint = `${API_ENDPOINTS.TICKET_TYPES}/`;
  const { t } = useTranslation(LOCALIZATION_PAGES.TICKET_TYPES);
  const { showNotification } = useRoot();
  const [path, setPath] = useState(endpoint + id);
  const [{ result, loading, error }, deleteTicketType] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.DELETE,
  });

  useEffect(() => {
    setPath(endpoint + id);
  }, [id, endpoint]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('ticket_type_delete_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result || result === '') {
      showNotification({
        message: t('ticket_type_delete_success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ result, loading }, deleteTicketType];
};

export default useDeleteTicketType;
