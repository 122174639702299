import React, { Suspense } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyle from 'globalStyle';
import { Row, Col } from 'reactstrap';

import routes, { matchRoute } from 'utils/routes';
import Alerts from 'components/Alerts/Alerts';
import Header from 'components/Layout/Header';
import SideMenu from 'components/Layout/SideMenu';
import Loader from 'components/Loader/Loader';

const Root = () => {
  const history = useHistory();

  // find active route
  const currentRoute = matchRoute(history.location.pathname);

  return (
    <Container>
      <GlobalStyle />
      <Alerts />
      <Suspense fallback={<Loader />}>
        {/* need a container here with columns, for layout */}
        <Container>
          <Column xs="auto">{currentRoute && currentRoute.showSideMenu && <SideMenu />}</Column>
          <Column>
            {currentRoute && currentRoute.showHeader && <Header currentRoute={currentRoute} />}

            {
              <Switch>
                {Object.values(routes).map((r, i) => {
                  return <Route key={i} path={r.path} exact={r.exact} component={r.Component} />;
                })}
                {/* if no match for the route - redirect to login */}
                <Route path="*">
                  <Redirect to={{ pathname: '/login' }} />
                </Route>
              </Switch>
            }
          </Column>
        </Container>
      </Suspense>
    </Container>
  );
};

const Container = styled(Row)`
  height: 100%;
  width: 100%;
  margin: 0;
`;

const Column = styled(Col)`
  padding-right: 0;
  padding-left: 0;
`;
export default Root;
