import React from 'react';
import styled from 'styled-components';
import { Row as ReacstrapRow } from 'reactstrap';

const RowWrapper = props => <Wrapper {...props}>{props.children}</Wrapper>;

const Wrapper = styled(ReacstrapRow)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export default RowWrapper;
