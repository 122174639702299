import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useUpdateUserRoles = (id, roles) => {
  const path = `users/${id}/roles`;
  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);

  const { showNotification } = useRoot();
  const [{ result, loading, error }, updateUserRoles] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
    data: roles,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('user_roles_update_failed'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('user_profile_update_success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ result, loading }, updateUserRoles];
};

export default useUpdateUserRoles;
