import { useEffect } from 'react';
import { useImmer } from 'use-immer';

const useQuery = (initialQuery, page, onChangePage) => {
  const [query, setQuery] = useImmer({ ...initialQuery });

  useEffect(() => {
    setQuery(draft => {
      draft.page = page - 1;
    });
  }, [page, setQuery]);

  useEffect(() => {
    setQuery(draft => {
      draft.page = 0;
    });
    onChangePage(1);
  }, [query.search, query.sortBy, setQuery, onChangePage]);
  return [query, setQuery];
};

export default useQuery;
