import { lazy } from 'react';

//import Authorization from 'components/Authorization/Authorization';
//import { ROLES } from 'utils/constants';
import { matchPath } from 'react-router-dom';
import { generatePath } from 'react-router';

import permissions from 'utils/permissions';
import Authorization from 'components/Authorization/Authorization';

//const SuperAdmin = Authorization([ROLES.SuperAdmin]);
//const Admin = Authorization([ROLES.SuperAdmin, ROLES.Admin]);
//const User = Authorization([ROLES.SuperAdmin, ROLES.Admin, ROLES.User]);

const Login = lazy(() => import('components/Login/Login'));
const ForgotPassword = lazy(() => import('components/Login/ForgotPassword'));
const Roles = lazy(() => import('components/Roles/Roles'));
const RoleEdit = lazy(() => import('components/Roles/RoleEdit'));
const UserList = lazy(() => import('components/Users/UserList'));
const AddUser = lazy(() => import('components/Users/AddUser'));
const MyProfile = lazy(() => import('components/Users/MyProfile'));
const UserProfile = lazy(() => import('components/Users/UserProfile'));
const ExternalUserProfile = lazy(() => import('components/Users/ExternalUserProfile'));
const SeatTypes = lazy(() => import('components/SeatTypes/SeatTypes'));
const VenuesList = lazy(() => import('components/Venues/List'));
const Screenings = lazy(() => import('components/Screenings/Screenings'));
const Venue = lazy(() => import('components/Venues/Venue'));
const VenueDisplay = lazy(() => import('components/VenuesSeating/SeatingDisplay'));
const ScreeningDisplay = lazy(() => import('components/ScreeningSeating/SeatingDisplay'));
const Accreditations = lazy(() => import('components/Accreditations/Accreditations'));
const PersonalAccreditation = lazy(() => import('components/Accreditations/PersonalAccreditation'));
const AccreditationGroup = lazy(() => import('components/Accreditations/AccreditationGroup'));
const TicketTypes = lazy(() => import('components/TicketTypes/TicketTypes'));
const ScreeningDetails = lazy(() => import('components/Screenings/ScreeningDetails/ScreeningDetails'));
const Discounts = lazy(() => import('components/Discounts/Discounts'));
const Settings = lazy(() => import('components/Settings/Settings'));
const Edition = lazy(() => import('components/Settings/Editions/Edition'));
const Reporting = lazy(() => import('components/Reporting/Reporting'));
const BallotingCategories = lazy(() => import('components/Balloting/Categories'));
const BallotingCategory = lazy(() => import('components/Balloting/Category/Category'));
const CustomerAwards = lazy(() => import('components/Balloting/CustomerAwards'));

const routesConfiguration = {
  LOGIN: {
    id: 'LOGIN',
    path: '/login',
    exact: true,
    Component: Login,
  },
  FORGOT_PASSWORD: {
    id: 'FORGOT_PASSWORD',
    path: '/forgot-password',
    exact: true,
    Component: ForgotPassword,
  },
  ROOT: {
    id: 'ROOT',
    path: '/',
    exact: true,
    Component: MyProfile,
    showHeader: true, // indicates Header component is included in the view
    showSideMenu: true, // indicates SideMenu component is included in the view
    config: {
      sideMenu: {
        activeLink: '',
      },
      breadcrumbs: {
        label: 'my_profile',
      },
    },
  },
  MY_PROFILE: {
    id: 'MY_PROFILE',
    path: '/profile',
    exact: true,
    Component: MyProfile,
    showHeader: true,
    showSideMenu: true,
    config: {
      sideMenu: {
        activeLink: '',
      },
      breadcrumbs: {
        label: 'my_profile',
      },
    },
  },
  USERS: {
    id: 'USERS',
    path: '/users',
    exact: true,
    Component: UserList,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewUsers, permissions.CanViewExternalUsers],
    config: {
      sideMenu: {
        order: 1, // position in the list
        isDisabled: false, // if we havent developed this feature - link should be isDisabled
        isSupport: false, // indicates the group on the side menu that this link belongs to
        label: 'user_management', // used for translation
        activeLink: 'USERS', // which link is active on the side menu while this route is active
      },
      breadcrumbs: {
        label: 'user_management', // used for translation
      },
    },
  },
  ADD_USER: {
    id: 'ADD_USER',
    path: '/users/add',
    exact: true,
    Component: AddUser,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanCreateUsers],
    config: {
      sideMenu: {
        activeLink: 'USERS',
      },
      breadcrumbs: {
        label: 'add_user', // used for translation
      },
    },
  },
  EDIT_USER: {
    id: 'EDIT_USER',
    path: '/users/:id',
    exact: true,
    Component: UserProfile,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanUpdateUsers],
    config: {
      sideMenu: {
        activeLink: 'USERS',
      },
      breadcrumbs: {
        label: 'update_user_profile',
      },
    },
  },
  VIEW_USER: {
    id: 'VIEW_USER',
    path: '/users/external/:id',
    exact: true,
    Component: ExternalUserProfile,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewExternalUsers],
    config: {
      sideMenu: {
        activeLink: 'USERS',
      },
      breadcrumbs: {
        label: 'user_profile',
      },
    },
  },
  ROLES: {
    id: 'ROLES',
    path: '/roles',
    exact: true,
    Component: Roles,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewRoles],
    config: {
      sideMenu: {
        order: 2,
        isDisabled: false,
        isSupport: false,
        label: 'roles',
        activeLink: 'ROLES',
      },
      breadcrumbs: {
        label: 'roles',
      },
    },
  },
  ROLE_EDIT: {
    id: 'ROLE_EDIT',
    path: '/roles/:id',
    exact: true,
    Component: RoleEdit,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['any', permissions.CanUpdateRoles, permissions.CanCreateRoles],
    config: {
      sideMenu: {
        activeLink: 'ROLES',
      },
      breadcrumbs: {
        label: 'add_role',
      },
    },
  },
  SCREENINGS: {
    id: 'SCREENINGS',
    path: '/screenings',
    exact: true,
    Component: Screenings,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewScreenings],
    config: {
      sideMenu: {
        order: 3,
        isDisabled: false,
        isSupport: false,
        label: 'screenings',
        activeLink: 'SCREENINGS',
      },
      breadcrumbs: {
        label: 'screenings',
      },
    },
  },
  SCREENING_DETAILS: {
    id: 'SCREENING_DETAILS',
    path: '/screenings/:id',
    exact: true,
    Component: ScreeningDetails,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewScreenings],
    config: {
      sideMenu: {
        activeLink: 'SCREENINGS',
      },
      breadcrumbs: {
        label: 'screenings',
      },
    },
  },
  EDIT_SCREENING_LAYOUT: {
    id: 'EDIT_SCREENING_LAYOUT',
    path: '/screenings/:id/layout',
    exact: true,
    Component: ScreeningDisplay,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanUpdateScreenings],
    config: {
      sideMenu: {
        activeLink: 'SCREENINGS',
      },
      breadcrumbs: {
        label: 'screenings',
      },
    },
  },
  SEAT_TYPES: {
    id: 'SEAT_TYPES',
    path: '/seat-types',
    exact: true,
    Component: SeatTypes,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewSeatTypes],
    config: {
      sideMenu: {
        order: 7,
        isDisabled: false,
        isSupport: false,
        label: 'seat_types',
        activeLink: 'SEAT_TYPES',
      },
      breadcrumbs: {
        label: 'seat_types',
      },
    },
  },
  VENUES_LIST: {
    id: 'VENUES_LIST',
    path: '/venues',
    exact: true,
    Component: VenuesList,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewVenues],
    config: {
      sideMenu: {
        order: 4,
        isDisabled: false,
        isSupport: false,
        label: 'venues',
        activeLink: 'VENUES_LIST',
      },
      breadcrumbs: {
        label: 'venues',
      },
    },
  },
  ADD_VENUE: {
    id: 'ADD_VENUE',
    path: '/venues/add',
    exact: true,
    Component: null,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanCreateVenuesLayout],
    config: {
      sideMenu: {
        activeLink: 'VENUES_LIST',
      },
      breadcrumbs: {
        label: 'venues',
      },
    },
  },
  EDIT_VENUE: {
    id: 'EDIT_VENUE',
    path: '/venues/:id',
    exact: true,
    Component: Venue,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewVenues],
    config: {
      sideMenu: {
        activeLink: 'VENUES_LIST',
      },
      breadcrumbs: {
        label: 'venues',
      },
    },
  },
  EDIT_VENUE_LAYOUT: {
    id: 'EDIT_VENUE_LAYOUT',
    path: '/venues/:id/layout',
    exact: true,
    Component: VenueDisplay,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanUpdateVenues],
    config: {
      sideMenu: {
        activeLink: 'VENUES_LIST',
      },
      breadcrumbs: {
        label: 'venues',
      },
    },
  },
  ACCREDITATIONS: {
    id: 'ACCREDITATIONS',
    path: '/accreditations',
    exact: true,
    Component: Accreditations,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewAccreditations],
    config: {
      sideMenu: {
        order: 6,
        isDisabled: false,
        isSupport: false,
        label: 'accreditations',
        activeLink: 'ACCREDITATIONS',
      },
      breadcrumbs: {
        label: 'accreditations',
      },
    },
  },
  PERSONAL_ACCREDITATION: {
    id: 'PERSONAL_ACCREDITATION',
    path: '/accreditations/personal/:id',
    exact: true,
    Component: PersonalAccreditation,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewAccreditations],
    config: {
      sideMenu: {
        activeLink: 'ACCREDITATIONS',
      },
      breadcrumbs: {
        label: 'accreditations',
      },
    },
  },
  GROUP_ACCREDITATION: {
    id: 'GROUP_ACCREDITATION',
    path: '/accreditations/group/:id',
    exact: true,
    Component: AccreditationGroup,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewAccreditations],
    config: {
      sideMenu: {
        activeLink: 'ACCREDITATIONS',
      },
      breadcrumbs: {
        label: 'accreditations',
      },
    },
  },
  TICKET_TYPES: {
    id: 'TICKET_TYPES',
    path: '/ticket-types',
    exact: true,
    Component: TicketTypes,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewTicketTypes],
    config: {
      sideMenu: {
        order: 8,
        isDisabled: false,
        isSupport: false,
        label: 'ticket_types',
        activeLink: 'TICKET_TYPES',
      },
      breadcrumbs: {
        label: 'ticket_types',
      },
    },
  },
  DISCOUNTS: {
    // not developed
    id: 'DISCOUNTS',
    path: '/discounts',
    exact: true,
    Component: Discounts,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewDiscounts],
    config: {
      sideMenu: {
        order: 9,
        isDisabled: false,
        isSupport: false,
        label: 'discounts',
        activeLink: 'DISCOUNTS',
      },
      breadcrumbs: {
        label: 'discounts',
      },
    },
  },
  SETTINGS: {
    id: 'SETTINGS',
    path: '/settings',
    exact: true,
    Component: Settings,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewSettings],
    config: {
      sideMenu: {
        order: 10,
        isDisabled: false,
        isSupport: false,
        label: 'settings',
        activeLink: 'SETTINGS',
      },
      breadcrumbs: {
        label: 'settings',
      },
    },
  },
  BALLOTING_CATEGORIES: {
    id: 'BALLOTING_CATEGORIES',
    path: '/balloting',
    exact: true,
    Component: BallotingCategories,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewBalloting],
    config: {
      sideMenu: {
        order: 11,
        isDisabled: false,
        isSupport: false,
        label: 'balloting',
        activeLink: 'BALLOTING',
      },
      breadcrumbs: {
        label: 'balloting',
      },
    },
  },
  BALLOTING_CATEGORY: {
    id: 'BALLOTING_CATEGORY',
    path: '/balloting/:id',
    exact: true,
    Component: BallotingCategory,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewBalloting],
    config: {
      sideMenu: {
        activeLink: 'BALLOTING',
      },
      breadcrumbs: {
        label: 'balloting',
      },
    },
  },
  CUSTOMER_AWARDS: {
    id: 'CUSTOMER_AWARDS',
    path: '/customer-awards',
    exact: true,
    Component: CustomerAwards,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewBalloting],
    config: {
      sideMenu: {
        activeLink: 'BALLOTING',
      },
      breadcrumbs: {
        label: 'balloting',
      },
    },
  },
  REPORTING: {
    id: 'REPORTING',
    path: '/reporting',
    exact: true,
    Component: Reporting,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['any', permissions.CanViewScreeningsReport, permissions.CanViewTicketsReport],
    config: {
      sideMenu: {
        order: 12,
        isDisabled: false,
        isSupport: false,
        label: 'reporting',
        activeLink: 'REPORTING',
      },
      breadcrumbs: {
        label: 'reporting',
      },
    },
  },
  EDITION: {
    id: 'EDITION',
    path: '/editions/:id',
    exact: true,
    Component: Edition,
    showHeader: true,
    showSideMenu: true,
    allowIf: ['all', permissions.CanViewSettings],
    config: {
      sideMenu: {
        order: 1,
        isDisabled: true,
        isSupport: true,
        label: '',
        activeLink: '',
      },
      breadcrumbs: {
        label: 'Edition',
      },
    },
  },
  CONTACT_ADMINISTRATOR: {
    // not developed
    id: 'CONTACT_ADMINISTRATOR',
    path: '/contact_administrator',
    exact: true,
    Component: null,
    showHeader: true,
    showSideMenu: true,
    config: {
      sideMenu: {
        order: 1,
        isDisabled: true,
        isSupport: true,
        label: 'contact_administrator',
        activeLink: 'CONTACT_ADMINISTRATOR',
      },
      breadcrumbs: {
        label: 'contact_administrator',
      },
    },
  },
  CONTACT_MISTRAL: {
    // not developed
    id: 'CONTACT_MISTRAL',
    path: '/contact_mistral',
    exact: true,
    Component: null,
    showHeader: true,
    showSideMenu: true,
    config: {
      sideMenu: {
        order: 2,
        isDisabled: true,
        isSupport: true,
        label: 'contact_mistral',
        activeLink: 'CONTACT_MISTRAL',
      },
      breadcrumbs: {
        label: 'contact_mistral',
      },
    },
  },
  KNOWLEDGE_BASE: {
    // not developed
    id: 'KNOWLEDGE_BASE',
    path: '/knowledge_base',
    exact: true,
    Component: null,
    showHeader: true,
    showSideMenu: true,
    config: {
      sideMenu: {
        order: 3,
        isDisabled: true,
        isSupport: true,
        label: 'knowledge_base',
        activeLink: 'KNOWLEDGE_BASE',
      },
      breadcrumbs: {
        label: 'knowledge_base',
      },
    },
  },
};

// export render ready routes but handle authorization first
// according to route configuration, wrap protected routes with withAuthorization HOC
export const routes = Object.values(routesConfiguration).map(route => {
  if (route.allowIf) {
    route.Component = Authorization(route.Component, route.allowIf);
  }
  return route;
});

/**
 * Find a matching route in all application routes
 * @param {String} pathname     Path to search for in routes.
 * @param {Boolean} exact       Find exact match or first partial match.
 */
export function matchRoute(pathname, exact) {
  return Object.values(routesConfiguration).find(r =>
    matchPath(pathname, {
      path: r.path,
      exact: exact == null ? r.exact : exact, // if exact flag provided use it otherwise use as specified in route r
    })
  );
}

/**
 * Generate path for given route id or route. Pass params for rouutes
 * which contain dynamic parts like query params
 * @param {String|Object} routeOrRouteId    Route ID as string or complete Route object to match.
 * @param {Object} params                   Object containing data for query parameters included in matched route.
 */

export function generateLink(routeOrRouteId, params) {
  let route;
  if (typeof routeOrRouteId === 'string') {
    route = routesConfiguration[routeOrRouteId];
  } else if (routeOrRouteId.hasOwnProperty('id') && routesConfiguration[routeOrRouteId.id]) {
    route = routeOrRouteId;
  }

  if (!route) {
    console.error(`Route not found error. Can't generate link for unknown route ${routeOrRouteId}`);
    return '#';
  }
  return generatePath(route.path, params);
}

export default routesConfiguration;
