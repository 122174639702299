import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { LOCALIZATION_PAGES } from 'utils/constants';
import { getDefaultLanguage, getCurrentLanguageFromStorage } from 'utils/localization/localization';

const LANG = getCurrentLanguageFromStorage() || getDefaultLanguage();

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: LANG.LANGUAGE_CODE,
    fallbackLng: LANG.LANGUAGE_CODE,
    ns: [LOCALIZATION_PAGES.COMMON],
    defaultNS: LOCALIZATION_PAGES.COMMON,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  });

export default i18n;
