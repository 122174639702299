import { useEffect, useState } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const endpoint = 'roles/';

const useDeleteRole = (id = '') => {
  const { t } = useTranslation(LOCALIZATION_PAGES.ROLES);
  const { showNotification } = useRoot();
  const [path, setPath] = useState(endpoint + id);

  useEffect(() => {
    setPath(endpoint + id);
  }, [id]);

  const [{ result: deleteRoleResult, loading: loadingDeleteRole, error }, deleteRole] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.DELETE,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('alert_delete_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (deleteRoleResult) {
      showNotification({
        message: t('alert_delete_success'),
      });
    }
  }, [deleteRoleResult, showNotification, t]);

  return [{ deleteRoleResult, loadingDeleteRole }, deleteRole];
};

export default useDeleteRole;
