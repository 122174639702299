import React from 'react';
import { Button as ReactstrapButton } from 'reactstrap';
import styled from 'styled-components';
import { FaSortUp, FaSortDown } from 'react-icons/fa';

const DatepickerToggle = ({ disabled, onClick, children, className }) => {
  return (
    <Button disabled={disabled} onClick={onClick} className={className}>
      {children}
      <IconWrapper>
        <SortIconUp />
        <SortIconDown />
      </IconWrapper>
    </Button>
  );
};

const Button = styled(ReactstrapButton)`
  border-radius: 4px;
  border: solid 1px #d8dce6;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 1.57;
  color: var(--dark);
`;

const IconWrapper = styled.div`
  display: inline;
  position: relative;
  margin-left: 20px;
`;

const SortIconUp = styled(FaSortUp)`
  opacity: 30%;
  position: absolute;
  top: 0;
  left: -10px;
`;

const SortIconDown = styled(FaSortDown)`
  opacity: 30%;
  position: absolute;
  top: 0;
  left: -10px;
`;

export default DatepickerToggle;
