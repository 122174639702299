import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const useRoles = (id = null) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.ROLES);
  let path = `roles/${id}`;

  const { showNotification } = useRoot();

  const [{ result: resultRole, loading: loadingRole, error: errorRole }, getRole] = useApi(path, {
    initialFetch: false,
  });

  useEffect(() => {
    if (errorRole) {
      showNotification(
        {
          message: t('alert_fetch_id_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorRole, showNotification, t]);

  return [{ resultRole, loadingRole, errorRole }, getRole];
};

export default useRoles;
