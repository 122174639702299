import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Flexbox = props => {
  return <Container {...props}>{props.children}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'unset')};
  flex-grow: ${props => (props.flexGrow ? props.flexGrow : 0)};
  flex-shrink: ${props => (props.flexShrink ? props.flexShrink : 0)};
  width: ${props => (props.flex ? `${props.flex}%` : '')};
  && > * {
    width: ${props => (props.fillContent ? '100%' : '')};
  }
`;

Flexbox.propTypes = {
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  flexWrap: PropTypes.string,
  flexGrow: PropTypes.string,
  flex: PropTypes.string,
  fillContent: PropTypes.string,
};

export default Flexbox;
