import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useDownloadTicketsReport = query => {
  const queryString = `${
    API_ENDPOINTS.REPORTING
  }/tickets/download/?discounts=${query.discounts.valueOf()}&films=${query.films.valueOf()}&users=${query.users.valueOf()}&screenings=${query.screenings.valueOf()}&eventivalAccreditationTypes=${query.eventivalAccreditationTypes.valueOf()}&paymentTypes=${query.paymentTypes.valueOf()}&programs=${query.programs.valueOf()}&screeningsCodes=${query.screeningCodes.valueOf()}&seatTypes=${query.seatTypes.valueOf()}&ticketTypes=${query.ticketTypes.valueOf()}&venues=${query.venues.valueOf()}&printDateStart=${
    query.printDateStart
  }&printDateEnd=${query.printDateEnd}&screeningDateStart=${query.screeningDateStart}&screeningDateEnd=${
    query.screeningDateEnd
  }&screeningStatus=${query.screeningStatus}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${
    query.retrieveAll
  }&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}`;
  const [path, setPath] = useState(queryString);
  const { t } = useTranslation(LOCALIZATION_PAGES.REPORTING);
  const { showNotification } = useRoot();
  const [{ error }, downloadTicketsReport] = useApi(path, {
    initialFetch: false,
    returnType: 'blob',
  });

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('tickets_report_download_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return downloadTicketsReport;
};

export default useDownloadTicketsReport;
