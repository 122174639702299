import React from 'react';
import styled from 'styled-components';
import ChromePicker from 'react-color';

const ColorPicker = ({ display, handleClose, onChange, ...rest }) =>
  display ? (
    <Popover>
      <Cover onClick={handleClose} />
      <ChromePicker {...rest} onChangeComplete={onChange} />
    </Popover>
  ) : null;

const Popover = styled.div`
  position: absolute;
  z-index: 100;
`;
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export default ColorPicker;
