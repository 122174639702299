import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useUpdateSalesPolicy = values => {
  const path = `/settings/sales`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SALES_POLICY);

  const { showNotification } = useRoot();
  const [
    { result: resultUpdateSalesPolicy, loading: loadingUpdateSalesPolicy, error: errorUpdateSalesPolicy },
    updateSalesPolicy,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
    data: values,
  });

  useEffect(() => {
    if (errorUpdateSalesPolicy) {
      showNotification(
        {
          message: t('alert_sales_policy_update_failed'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorUpdateSalesPolicy, showNotification, t]);

  useEffect(() => {
    if (resultUpdateSalesPolicy) {
      showNotification({
        message: t('alert_sales_policy_update_success'),
      });
    }
  }, [resultUpdateSalesPolicy, showNotification, t]);

  return [{ resultUpdateSalesPolicy, loadingUpdateSalesPolicy, errorUpdateSalesPolicy }, updateSalesPolicy];
};

export default useUpdateSalesPolicy;
