import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, HTTP_VERBS } from 'utils/constants';

const path = '/sync';

const useSyncEdition = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SETTINGS);
  const { showNotification } = useRoot();
  const [
    { result: syncEditionStarted, loading: loadingStartSyncEdition, error: errorStartSyncEdition, setResult },
    syncEdition,
  ] = useApi(path, { initialFetch: false, verb: HTTP_VERBS.POST });

  useEffect(() => {
    if (errorStartSyncEdition) {
      showNotification(
        {
          message: t('alert_sync_edition_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorStartSyncEdition, showNotification, t]);

  useEffect(() => {
    if (syncEditionStarted) {
      showNotification({
        message: t('alert_sync_edition_success'),
      });
    }

    if (syncEditionStarted === false) {
      showNotification(
        {
          message: t('sync_in_progress'),
        },
        ALERT_VARIANTS.WARNING
      );

      setResult(null);
    }
  }, [syncEditionStarted, showNotification, setResult, t]);

  return [{ syncEditionStarted, loadingStartSyncEdition, errorStartSyncEdition }, syncEdition];
};

export default useSyncEdition;
