import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useDiscounts = query => {
  const queryString = `${API_ENDPOINTS.DISCOUNTS}?Code=${query.code}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const [path, setPath] = useState(queryString);
  const { t } = useTranslation(LOCALIZATION_PAGES.DISCOUNTS);
  const { showNotification } = useRoot();
  const [{ result: discounts, loading, error }, getDiscounts] = useApi(path);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('discounts_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ discounts, loading, error }, getDiscounts];
};

export default useDiscounts;
