import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useScreeningsReport = query => {
  const queryString = `${
    API_ENDPOINTS.REPORTING
  }/screenings/?discounts=${query.discounts.valueOf()}&films=${query.films.valueOf()}&distributors=${query.distributors.valueOf()}&users=${query.users.valueOf()}&screenings=${query.screenings.valueOf()}&eventivalAccreditationTypes=${query.eventivalAccreditationTypes.valueOf()}&paymentTypes=${query.paymentTypes.valueOf()}&saleTypes=${query.saleTypes.valueOf()}&programs=${query.programs.valueOf()}&screeningCodes=${query.screeningCodes.valueOf()}&seatTypes=${query.seatTypes.valueOf()}&ticketTypes=${query.ticketTypes.valueOf()}&venues=${query.venues.valueOf()}&printDateStart=${
    query.printDateStart
  }&printDateEnd=${query.printDateEnd}&screeningDateStart=${query.screeningDateStart}&screeningDateEnd=${
    query.screeningDateEnd
  }&screeningStatus=${query.screeningStatus}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${
    query.retrieveAll
  }&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}`;
  const [path, setPath] = useState(queryString);
  const { t } = useTranslation(LOCALIZATION_PAGES.REPORTING);
  const { showNotification } = useRoot();
  const [{ result, loading, error }, getScreeningsReport] = useApi(path);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('screenings_report_fetch_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ result, loading, error }, getScreeningsReport];
};

export default useScreeningsReport;
