import React from 'react';
import PropTypes from 'prop-types';
import { Button as ReacstrapButton, Spinner } from 'reactstrap';
import styled, { css } from 'styled-components';

const Button = ({ color = 'primary', loading, disabled, children, ...rest }) => (
  <CustomButton color={color} disabled={loading || disabled} {...rest}>
    {loading ? (
      <>
        <Loader color="light" size="sm" />
        {children}
      </>
    ) : (
      children
    )}
  </CustomButton>
);

Button.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
};

const CustomButton = styled(ReacstrapButton)`
  font-size: 14px;
  line-height: 1.57;
  box-shadow: 0px 1px 1px 0 rgba(19, 31, 21, 0.1);
  ${({ color }) => {
    switch (color) {
      case 'dark':
        return css`
          background-color: #2a3a46;
          &:hover {
            background-color: #2a3a46;
          }
        `;
      case 'light':
        return css`
          border: solid 1px #d8dce6;
          background-color: #f6f7f9;
        `;
      default:
        return;
    }
  }}
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

const Loader = styled(Spinner)`
  margin-right: 10px;
`;

export default Button;
