import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useCreateUser = user => {
  const path = 'users';
  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);

  const { showNotification } = useRoot();
  const [{ result: createUserResult, loading: loadingCreateUser, error }, createUser] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
    data: user,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('user_create_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (createUserResult) {
      showNotification({
        message: t('user_create_success'),
      });
    }
  }, [createUserResult, showNotification, t]);

  return [{ createUserResult, loadingCreateUser }, createUser];
};

export default useCreateUser;
