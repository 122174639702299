import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';

const useScreeningSeatTypeDistribution = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();

  const path = `${API_ENDPOINTS.SCREENINGS}/${id}/seats/distribution`;

  const [
    { result: seatsDistribution, loading: loadingSeatsDistribution, error: errorSeatsDistribution },
    getSeatTypeDistribution,
  ] = useApi(path);

  useEffect(() => {
    if (errorSeatsDistribution) {
      showNotification(
        {
          message: t('failed_to_fetch_seats_type'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorSeatsDistribution, showNotification, t]);

  return [{ seatsDistribution, loadingSeatsDistribution, errorSeatsDistribution }, getSeatTypeDistribution];
};

export default useScreeningSeatTypeDistribution;
