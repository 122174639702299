import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import routes from 'utils/routes';

const useScreening = id => {
  const path = `${routes.SCREENINGS.path}/${id}?details=true`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);

  const { showNotification } = useRoot();

  const [
    { result: screening, setResult: setScreening, loading: loadingScreening, error: errorScreening },
    getScreening,
  ] = useApi(path);

  useEffect(() => {
    if (errorScreening) {
      showNotification(
        {
          message: t('alert_screening_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorScreening, showNotification, t]);

  return [{ screening, setScreening, loadingScreening, errorScreening }, getScreening];
};

export default useScreening;
