import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { usePath } from 'utils/hooks';

const useEdition = id => {
  const path = usePath(`editions/${id}`);
  const { t } = useTranslation(LOCALIZATION_PAGES.SETTINGS);
  const { showNotification } = useRoot();
  const [{ result: edition, loading: loadingEdition, error: errorEdition, setResult: setEdition }, getEdition] = useApi(
    path
  );

  useEffect(() => {
    if (errorEdition) {
      showNotification(
        {
          message: t('edition_fetch_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorEdition, showNotification, t, id]);

  return [{ edition, loadingEdition, errorEdition, setEdition }, getEdition];
};

export default useEdition;
