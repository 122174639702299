import { useEffect } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const useUpdateRole = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.ROLES);
  const { showNotification } = useRoot();
  const path = `roles/${id}`;

  const [{ result: updateRoleResult, loading: loadingUpdateRole, error }, updateRole] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('alert_update_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (updateRoleResult) {
      showNotification({
        message: t('alert_update_success'),
      });
    }
  }, [updateRoleResult, showNotification, t]);

  return [{ updateRoleResult, loadingUpdateRole }, updateRole];
};

export default useUpdateRole;
