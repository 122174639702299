import { setCurrentLanguageInStorage } from 'utils/localization/localization';
import * as TokenService from 'utils/tokenService';
import constants from 'redux/constants';

export const setLanguage = language => {
  setCurrentLanguageInStorage(language);
  return {
    type: constants.session.SET_LANGUAGE,
    payload: { language },
  };
};

export const logOut = () => {
  TokenService.removeTokens();
  return {
    type: constants.session.LOGOUT,
  };
};

export const setUserInfo = user => {
  return {
    type: constants.session.SET_USER_INFO,
    payload: { user },
  };
};
