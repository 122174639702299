import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import * as TokenService from 'utils/tokenService';
import { API_ENDPOINTS } from 'utils/constants';

const useRefreshToken = () => {
  const refreshToken = TokenService.getRefreshToken();
  const [
    { result: refreshTokenResult, loading: loadingRefreshToken, error: errorRefreshToken },
    getRefreshToken,
  ] = useApi(API_ENDPOINTS.REFRESH_AUTH_TOKEN, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
    data: {
      refreshToken,
    },
  });

  return [{ refreshTokenResult, loadingRefreshToken, errorRefreshToken }, getRefreshToken];
};

export default useRefreshToken;
