import { LANGUAGES, MARKINGS, ORIENTATION } from 'utils/constants';
import moment from 'moment';

export const getDefaultLanguage = () => {
  const lang = LANGUAGES.find(l => l.DEFAULT);
  return lang || LANGUAGES[0];
};

export const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const tableDataHandler = value => (value || value === 0 ? value : '-');
export const percentageDataHandler = value => (value || value === 0 ? `${value}%` : '-');

export const dateAndTimeHandler = (values, type) => {
  if (values) {
    if (type === 'date') return moment(values).format('DD.MM.YYYY');
    if (type === 'time') return moment(values).format('HH:mm');
    if (type === 'dateandtime') return moment(values).format('DD.MM.YYYY HH:mm');
  } else return '-';
};

export const getTicketStatus = status => {
  switch (status) {
    case 1:
      return 'paid';
    case 2:
      return 'used';
    case 3:
      return 'cancelled';
    default:
      return '-';
  }
};

export const escapeNull = (value, char = '-') => (value ? value : char);

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

/////////////////////////////////////
// --------------START--------------
// Methods used on 4 different layouts
/////////////////////////////////////

export const generateNumericArray = arrLength => {
  return Array.from(Array(arrLength), (_, i) => i + 1);
};

// used on Venues, Screenings, ScreeningPreview and ReservedSeatingPreview
export const generateLabels = (isReversed, lablesLength) => {
  let alphabetLabels = [];
  let numericLabels = [];

  if (isReversed) {
    alphabetLabels = alphabet.slice(0, lablesLength).reverse();
    numericLabels = generateNumericArray(lablesLength).reverse();
  } else {
    alphabetLabels = alphabet.slice(0, lablesLength + 1);
    numericLabels = generateNumericArray(lablesLength);
  }

  let generatedLabels = [];
  generatedLabels['alphabetLabels'] = alphabetLabels;
  generatedLabels['numericLabels'] = numericLabels;

  return generatedLabels;
};

// used on Venues, Screenings, ScreeningPreview and ReservedSeatingPreview
export const generateLabel = (x, i, j) => {
  // consider to move these labels generators (if you find time to optimize)
  // generate label for row
  const areRowsReversed = x?.rowsOrientation?.toString() === ORIENTATION.reverse.name.toString();
  let rowsLabels = generateLabels(areRowsReversed, x?.rowsCount);
  const l1 =
    x?.rowsMarking?.toLowerCase() === MARKINGS.letters.name.toLowerCase()
      ? rowsLabels.alphabetLabels[i]
      : rowsLabels.numericLabels[i];
  // generate label for column
  const areColumnsReversed = x?.columnsOrientation?.toString() === ORIENTATION.reverse.name.toString();
  let columnsLabels = generateLabels(areColumnsReversed, x?.columnsCount);
  const l2 =
    x?.columnsMarking?.toLowerCase() === MARKINGS.letters.name.toLowerCase()
      ? columnsLabels.alphabetLabels[j]
      : columnsLabels.numericLabels[j];
  // row | column
  return `${l1}|${l2}`;
};

// generateMatrix for Venues and Screenings
export const generateMatrix = (x, seatTypes, ticketTypes, seats) => {
  const matrix = [];

  if (x && x.rowsCount && x.columnsCount && seatTypes && ticketTypes) {
    for (let i = 0; i < x?.rowsCount; i++) {
      matrix.push([]);
      let labelJ = 0;
      for (let j = 0; j < x?.columnsCount; j++) {
        let item = {};

        let defaultSeat = seatTypes.data?.find(st => st.isDefault);
        let defaultTicket = ticketTypes.data?.find(tt => tt.isDefault);

        // seat defaults
        let seatTypeId = defaultSeat.id;
        let ticketTypeId = defaultTicket.id;
        let isAvailable = true;
        let isSold = false;
        let seatId = null;

        // if seat exists copy its data
        const seat = seats?.data?.find(s => s.row === i && s.number === j);
        if (seat) {
          seatTypeId = seat.seatTypeId;
          ticketTypeId = seat.ticketTypeId;
          isAvailable = seat.isAvailable;
          seatId = seat.id;
          isSold = seat?.isSold;
        }

        // set matrix item
        item = {
          row: i,
          number: j,
          label: isAvailable ? generateLabel(x, i, labelJ) : '',
          seatTypeId,
          ticketTypeId,
          isAvailable,
          isSold,
          id: seatId,
        };
        matrix[i][j] = item;
        if (isAvailable) {
          labelJ++;
        }
      }
    }
  }
  return matrix;
};

// generateMatrix for Venues and Screenings
export const generateMatrixRow = (matrixMetadata, isReversed, seatTypes, ticketTypes) => {
  const row = [];
  let defaultSeat = seatTypes.data?.find(st => st.isDefault);
  let defaultTicket = ticketTypes.data?.find(tt => tt.isDefault);

  for (let j = 0; j < matrixMetadata.columnsCount; j++) {
    let item = {};
    // seat defaults
    let seatTypeId = defaultSeat.id;
    let ticketTypeId = defaultTicket.id;
    let isAvailable = true;
    let isSold = false;
    let seatId = null;

    // set matrix item
    item = {
      row: isReversed ? 0 : matrixMetadata.rowsCount - 1,
      number: j,
      label: generateLabel(matrixMetadata, isReversed ? 0 : matrixMetadata.rowsCount - 1, j),
      seatTypeId,
      ticketTypeId,
      isAvailable,
      isSold,
      id: seatId,
      addedRow: true,
    };
    row[j] = item;
  }
  return row;
};

// updateMatrixLabels for Venues and Screenings
export const updateMatrixLabels = (x, layout) => {
  const matrix = [];
  if (x && layout && layout[0] && layout[0].length > 0) {
    for (let i = 0; i < layout.length; i++) {
      matrix.push([]);

      if (x.columnsOrientation?.toString() === ORIENTATION.reverse.name.toString()) {
        let labelJ = layout[0].length - 1;
        for (let j = layout[0].length - 1; j >= 0; j--) {
          let seat = layout[i][j];
          let label = seat.isAvailable ? generateLabel(x, i, labelJ) : '';
          matrix[i][j] = label;

          if (seat.isAvailable) {
            labelJ--;
          }
        }
      } else {
        let labelJ = 0;
        for (let j = 0; j < layout[0].length; j++) {
          let seat = layout[i][j];
          let label = seat.isAvailable ? generateLabel(x, i, labelJ) : '';
          matrix[i][j] = label;

          if (seat.isAvailable) {
            labelJ++;
          }
        }
      }
    }
  }
  return matrix;
};

// generateMatrix for preview, used on ScreeningPreview and ReservedSeatingPreview
export const generatePreviewMatrix = (x, seatTypes, ticketTypes, availableSeats) => {
  const matrix = [];
  if (x && seatTypes && ticketTypes) {
    for (let i = 0; i < x.rowsCount; i++) {
      matrix.push([]);

      for (let j = 0; j < x.columnsCount; j++) {
        let item = {};

        let defaultSeat = null;
        let defaultTicket = null;
        if (seatTypes) defaultSeat = seatTypes.data.find(st => st.isDefault);
        if (ticketTypes) defaultTicket = ticketTypes.data.find(tt => tt.isDefault);

        let seatTypeId = defaultSeat.id;
        let ticketTypeId = defaultTicket.id;
        let isSold = false;
        let isAvailable = true;
        let seatId = null;

        const seat = availableSeats?.reservedSeats?.reservedSeats?.find(s => s.row === i && s.number === j);
        if (availableSeats && availableSeats?.reservedSeats?.reservedSeats) {
          seatTypeId = seat?.seatTypeId;
          ticketTypeId = seat?.ticketTypeId;
          isAvailable = seat?.isAvailable;
          isSold = seat?.isSold;
          seatId = seat?.id;
        }
        item = {
          row: i,
          number: j,
          label: isAvailable ? seat?.label : '',
          seatTypeId,
          ticketTypeId,
          isAvailable,
          isSold,
          id: seatId,
        };
        matrix[i][j] = item;
      }
    }
  }
  return matrix;
};

/////////////////////////////////////
// ---------------END---------------
/////////////////////////////////////

export const toLocalDateTime = date =>
  moment
    .parseZone(date)
    .local()
    .toDate();

/**
 * Function returns a boolean indicating if user is allowed by given input
 *
 * Sample input: ['all', permissions.CanAccessConnectConnections, someFunction].
 * For given input, user will be allowed if he has CanAccessConnectConnections permission attached
 * and if someFunction evaluates to a truthy value
 * @param {Array} params
 */
export function isAllowed([operator, ...values], authData) {
  const evaluator = v => {
    if (typeof v === 'function') {
      return v(authData);
    } else if (Array.isArray(v)) {
      return isAllowed(v, authData);
    } else if (typeof v === 'boolean') {
      return v;
    } else if (typeof v === 'string') {
      const { user } = authData;
      return user && user?.permissions?.indexOf(v) !== -1;
    } else {
      console.warn('Unknown type for evaluation');
    }
  };

  switch (operator) {
    case 'all':
      return values.every(evaluator);
    case 'any':
      return values.some(evaluator);
    case 'none':
      return !values.some(evaluator);
    default:
      console.error('Invalid operator. Will not allow!');
      return false;
  }
}
