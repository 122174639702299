import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useReportingFilters = () => {
  const path = `${API_ENDPOINTS.REPORTING}/filters`;
  const { t } = useTranslation(LOCALIZATION_PAGES.REPORTING);
  const { showNotification } = useRoot();
  const [{ result: filters, loading: filtersLoading, error }, getReportingFilters] = useApi(path);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('reporting_filters_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ filters, filtersLoading, error }, getReportingFilters];
};

export default useReportingFilters;
