import React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';

import FilterInput from 'components/FilterInput/FilterInput';

const ListHeader = ({ onSearch, searchTerm, searchPlaceholder, disabled, children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Row className="d-flex justify-content-between">
        <Col xs={8} sm={4}>
          <FilterInput
            onSearch={onSearch}
            searchTerm={searchTerm}
            disabled={disabled}
            placeholder={searchPlaceholder}
          />
        </Col>
        <Col xs={4} sm={8} className="d-flex align-items-center justify-content-end mr-auto">
          {children}
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* margin-top: 2.5rem; */
  margin-bottom: 2rem;
  width: 100%;
  margin-bottom: 2rem;
`;

export default ListHeader;
