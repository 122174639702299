import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, HTTP_VERBS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useUpdateEdition = id => {
  const path = `${API_ENDPOINTS.EDITIONS}/${id}`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SETTINGS);
  const { showNotification } = useRoot();
  const [
    { result: resultUpdateEdition, loading: loadingUpdateEdition, error: errorUpdateEdition },
    updateEdition,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorUpdateEdition) {
      showNotification(
        {
          message: t('alert_update_edition_fail'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorUpdateEdition, showNotification, t]);

  useEffect(() => {
    if (resultUpdateEdition) {
      showNotification({
        message: t('alert_update_edition_success'),
      });
    }
  }, [resultUpdateEdition, showNotification, t]);

  return [{ resultUpdateEdition, loadingUpdateEdition, errorUpdateEdition }, updateEdition];
};

export default useUpdateEdition;
