import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

const useAuthentication = () => {
  const [{ result: authResult, loading: loadingAuth, error: errorAuth }, login] = useApi(API_ENDPOINTS.AUTH_TOKEN, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
  });
  return [{ authResult, loadingAuth, errorAuth }, login];
};

export default useAuthentication;
