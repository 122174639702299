import React from 'react';
import styled from 'styled-components';
import { Navbar, Nav } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

import LanguageSelect from 'components/LanguageSelect/LanguageSelect';
import HeaderMenu from 'components/Layout/HeaderMenu';

const Header = ({ currentRoute }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  return (
    <Navigation>
      <Breadcrumbs>
        {currentRoute.config && currentRoute.config.breadcrumbs ? t(currentRoute.config.breadcrumbs.label) : ''}
      </Breadcrumbs>
      <Nav className="mr-auto" />
      <LanguageSelect />
      <HeaderMenu />
    </Navigation>
  );
};

const Navigation = styled(Navbar)`
  height: 80px;
  box-shadow: 0px 1px 0 0 #eaedf3;
  background-color: var(--white);
  align-items: center;
`;

const Breadcrumbs = styled.div`
  font-size: 18px;
  line-height: 26px;
  color: --var(dark);
`;

export default Header;
