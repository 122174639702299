import React from 'react';
import styled from 'styled-components';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, FormText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useRoot } from 'context/RootContext';
import { useToggle } from 'utils/hooks';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { getUser } from 'redux/reducers/sessionReducer';
import routes from 'utils/routes';
import { CardBody } from 'components/Card';

const HeaderMenu = () => {
  const [dropdownOpen, setDropdownOpen] = useToggle(false);
  const { t } = useTranslation(LOCALIZATION_PAGES.HEADER);
  const history = useHistory();
  const user = useSelector(getUser);
  const { firstName, lastName, email } = user || {};
  const fullName = `${firstName} ${lastName}`;
  const { setIsLoggingOut } = useRoot();

  const logOut = async () => {
    setIsLoggingOut(true);
    history.push(routes.LOGIN.path);
  };

  const goToProfile = async () => {
    history.push(routes.MY_PROFILE.path);
  };

  const menu = [
    {
      iconPath: `${process.env.PUBLIC_URL}/assets/icons/list-outline.svg`,
      label: t('my_profile'),
      onClick: goToProfile,
    },
    {
      iconPath: `${process.env.PUBLIC_URL}/assets/icons/log-out-outline.svg`,
      label: t('log_out'),
      onClick: logOut,
    },
  ];

  return (
    <CustomDropdown isOpen={dropdownOpen} toggle={setDropdownOpen}>
      <CustomToggle color="transparent">
        {user && firstName && lastName && (
          <Initials>
            {firstName[0]}
            {lastName[0]}
          </Initials>
        )}
        <Icon src={`${process.env.PUBLIC_URL}/assets/icons/select.svg`} alt={t('select_icon')} />
      </CustomToggle>
      <DropdownMenu right>
        <DropdownItem header>
          {fullName}
          <FormText>{email}</FormText>
        </DropdownItem>
        <DropdownItem divider />
        {menu.map(m => {
          return (
            <CustomDropdownItem onClick={m.onClick} key={m.label}>
              <CustomImg alt={m.label} src={m.iconPath} />
              <CustomFormText color="dark">{m.label}</CustomFormText>
            </CustomDropdownItem>
          );
        })}
      </DropdownMenu>
    </CustomDropdown>
  );
};

const CustomDropdown = styled(Dropdown)`
  margin-left: 1.5rem;
`;

const CustomDropdownItem = styled(DropdownItem)`
  display: flex;
  align-items: center;
`;

const CustomImg = styled.img`
  margin-right: 0.8rem;
`;

const CustomFormText = styled(FormText)`
  font-size: 15px;
  margin: 0;
`;

const Initials = styled(CardBody)`
  height: 38px;
  width: 38px;
  background-color: var(--secondary);
  color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 100%;
  padding: 0px;
  float: left;
  margin: 0px !important;
`;

const CustomToggle = styled(DropdownToggle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 38px;
  padding: 0px;
  border: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  &:active {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  &:focus {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`;

const Icon = styled.img`
  height: 13px;
  width: 13px;
`;

export default HeaderMenu;
