import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

const path = API_ENDPOINTS.SCREENINGS;

const useScreeningUpdateLayout = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();

  const [
    { result: updateScreeningLayoutResult, loading: loadingUpdateLayoutScreening, error },
    updateScreening,
  ] = useApi(`${path}/${id}/layout`, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('layout_save_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (updateScreeningLayoutResult) {
      showNotification({
        message: t('layout_save_success'),
      });
    }
  }, [updateScreeningLayoutResult, showNotification, t]);

  return [{ updateScreeningLayoutResult, loadingUpdateLayoutScreening, error }, updateScreening];
};

export default useScreeningUpdateLayout;
