import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { Input } from 'components/Form';
import { DEBOUNCE_TIMEOUT, LOCALIZATION_PAGES } from 'utils/constants';
import { useDebounce } from 'utils/hooks';

const FilterInput = ({ searchTerm = '', placeholder = '', onSearch, ...rest }) => {
  const { t: tCommon } = useTranslation(LOCALIZATION_PAGES.COMMON);
  const [keyword, setKeyword] = useState(searchTerm);
  const debouncedSearchTerm = useDebounce(keyword, DEBOUNCE_TIMEOUT);

  if (!placeholder) {
    placeholder = tCommon('search_text');
  }

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  const onChange = e => {
    const search = e.target.value;
    setKeyword(search);
  };

  return (
    <InputWrapper>
      <SearchIcon />
      <Container placeholder={placeholder} onChange={onChange} value={keyword} {...rest} />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
  display: inline;
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 11px;
  left: 10px;
  z-index: 1;
  opacity: 0.5;
  ${'@-moz-document url-prefix()'} {
    & {
      top: 25px;
    }
  }
`;

const Container = styled(Input)`
  padding-left: 35px;
`;

export default FilterInput;
