import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, API_QUERY } from 'utils/constants';

const useBallotingUnassignedScreenings = (query = API_QUERY.unassignedBallotingScreenings) => {
  const path = `${API_ENDPOINTS.SCREENINGS}/balloting?Term=${
    query.term
  }&ProgramIds=${query.programIds.valueOf()}&VenueIds=${query.venueId}&PageSize=${query.pageSize}&Page=${
    query.page
  }&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${
    query.sortOrder
  }&Includes=${query.includes}`;
  const { t } = useTranslation(LOCALIZATION_PAGES.BALLOTING);
  const { showNotification } = useRoot();
  const [
    { result: unassignedBallotingScreenings, loadingUnassignedBallotingScreenings, error },
    getUnassignedBallotingScreenings,
  ] = useApi(path, { initialFetch: false });

  useEffect(() => {
    if (error)
      showNotification(
        {
          message: t('balloting_unassigned_screenings_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
  }, [error, showNotification, t]);

  return [{ unassignedBallotingScreenings, loadingUnassignedBallotingScreenings }, getUnassignedBallotingScreenings];
};

export default useBallotingUnassignedScreenings;
