import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button/Button';

const SecondaryButton = ({ children, ...rest }) => {
  return (
    <Wrapper color="light" {...rest}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(Button)`
  box-shadow: 0px 1px 1px 0 rgba(22, 29, 37, 0.05);
  border: solid 1px #d8dce6;
  background-color: #f6f7f9;
  color: #3d3d3d;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: fit-content;
`;

export default SecondaryButton;
