import React from 'react';
import styled from 'styled-components';
import { Container as ReacstrapContainer } from 'reactstrap';

const Container = props => <Wrapper {...props}>{props.children}</Wrapper>;

const Wrapper = styled(ReacstrapContainer)`
  margin-top: 2.5rem;
  max-width: 730px;
  padding: 0;
  margin: 0 0;
`;

export default Container;
