import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, HTTP_VERBS } from 'utils/constants';

const useBallotingScreeningsDelete = id => {
  const path = `${API_ENDPOINTS.BALLOTING_CATEGORIES}/${id}/screenings/remove`;
  const { t } = useTranslation(LOCALIZATION_PAGES.BALLOTING);
  const { showNotification } = useRoot();
  const [
    {
      result: resultDeleteBallotingScreenings,
      loadingDeleteBallotingScreenings,
      error: errorDeleteBallotingScreenings,
      setResult: setResultDeleteBallotingScreenings,
    },
    DeleteBallotingScreenings,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorDeleteBallotingScreenings)
      showNotification(
        {
          message: t('balloting_category_screenings_delete_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
  }, [errorDeleteBallotingScreenings, showNotification, t]);

  return [
    {
      resultDeleteBallotingScreenings,
      loadingDeleteBallotingScreenings,
      errorDeleteBallotingScreenings,
      setResultDeleteBallotingScreenings,
    },
    DeleteBallotingScreenings,
  ];
};

export default useBallotingScreeningsDelete;
