import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

const path = API_ENDPOINTS.VENUES;

const useVenueDeleteLayout = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.VENUES);
  const { showNotification } = useRoot();

  const [
    {
      result: deleteVenueLayoutResult,
      loading: loadingDeleteLayoutVenue,
      error,
      setResult: setDeleteVenueLayoutResult,
    },
    deleteVenue,
  ] = useApi(`${path}/${id}/layout`, {
    initialFetch: false,
    verb: HTTP_VERBS.DELETE,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('layout_delete_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (deleteVenueLayoutResult) {
      showNotification({
        message: t('layout_delete_success'),
      });
    }
  }, [deleteVenueLayoutResult, showNotification, t]);

  return [{ deleteVenueLayoutResult, loadingDeleteLayoutVenue, error, setDeleteVenueLayoutResult }, deleteVenue];
};

export default useVenueDeleteLayout;
