import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const path = 'roles/permissions';

const usePermissions = () => {
  const { showNotification } = useRoot();

  const [{ result: resultRole, loading: loadingPermissions, error: errorPermissions }] = useApi(path);
  useEffect(() => {
    if (errorPermissions) {
      showNotification({
        message: 'Could not fetch permissions',
        variant: ALERT_VARIANTS.DANGER,
      });
    }
  }, [errorPermissions, showNotification]);

  return { resultRole, loadingPermissions, errorPermissions };
};

export default usePermissions;
