import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useTicketTypesSelector = () => {
  const path = API_ENDPOINTS.TICKET_TYPES_SELECTOR;

  const { t } = useTranslation(LOCALIZATION_PAGES.TICKET_TYPE);
  const { showNotification } = useRoot();
  const [{ result: types, loading, error }, getTypes] = useApi(path, { initialFetch: false });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('types_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ types, loading, error }, getTypes];
};

export default useTicketTypesSelector;
