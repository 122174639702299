import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { sessionActions } from 'redux/actions';

const useSession = () => {
  const path = '/users/current';

  const dispatch = useDispatch();

  const [{ result: session, loading: loadingSession, error }, getSession] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.GET,
  });

  useEffect(() => {
    async function fetchSession() {
      if (session) {
        // put current session in global storage
        dispatch(sessionActions.setUserInfo(session));
      }
    }
    fetchSession();
  }, [session, dispatch]);

  return [{ session, loadingSession, error }, getSession];
};

export default useSession;
