import { useEffect, useState } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';

const useDeleteSeatType = (id = '') => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SEAT_TYPES);
  const { showNotification } = useRoot();
  const [path, setPath] = useState(`${API_ENDPOINTS.SEAT_TYPES}/${id}`);

  useEffect(() => {
    setPath(`${API_ENDPOINTS.SEAT_TYPES}/${id}`);
  }, [id]);

  const [
    { result: deleteSeatTypeResult, loading: loadingDeleteSeatType, error: errorDeleteSeatType },
    deleteSeatType,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.DELETE,
  });

  useEffect(() => {
    if (errorDeleteSeatType) {
      showNotification(
        {
          message: t('alert_delete_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorDeleteSeatType, showNotification, t]);

  useEffect(() => {
    if (deleteSeatTypeResult) {
      showNotification({
        message: t('alert_delete_success'),
      });
    }
  }, [deleteSeatTypeResult, showNotification, t]);

  return [{ deleteSeatTypeResult, loadingDeleteSeatType, errorDeleteSeatType }, deleteSeatType];
};

export default useDeleteSeatType;
