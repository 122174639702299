import React from 'react';
import styled, { css } from 'styled-components';
import { Label as ReacstrapLabel } from 'reactstrap';

const Label = props => (
  <Wrapper {...props} color={props.color}>
    {props.children}
  </Wrapper>
);

const Wrapper = styled(({ isDisabled, children, ...rest }) => <ReacstrapLabel {...rest}>{children}</ReacstrapLabel>)`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  color: var(--secondary);
  letter-spacing: normal;
  text-transform: uppercase;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
    `}
`;

export default Label;
