import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useSalesPolicy = () => {
  const path = `/settings/sales`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SALES_POLICY);

  const { showNotification } = useRoot();
  const [{ result: salesPolicy, loading: loadingSalesPolicy, error: errorSalesPolicy }, getSalesPolicy] = useApi(path);

  useEffect(() => {
    if (errorSalesPolicy) {
      showNotification(
        {
          message: t('alert_sales_policy_fetch_failed'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorSalesPolicy, showNotification, t]);

  return [{ salesPolicy, loadingSalesPolicy, errorSalesPolicy }, getSalesPolicy];
};

export default useSalesPolicy;
