import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { HTTP_VERBS } from 'utils/constants';

const useScreeningUpdateSeatTypeDistribution = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();

  const path = `${API_ENDPOINTS.SCREENINGS}/${id}/seats/distribution`;

  const [
    {
      result: seatsDistributionUpdateResult,
      loading: loadingSeatsDistributionUpdate,
      error: errorSeatsDistributionUpdate,
    },
    updateSeatTypeDistribution,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (seatsDistributionUpdateResult) {
      showNotification({
        message: t('seats_types_updates'),
      });
    }
  }, [seatsDistributionUpdateResult, showNotification, t]);

  useEffect(() => {
    if (errorSeatsDistributionUpdate || seatsDistributionUpdateResult === false) {
      showNotification(
        {
          message: t('failed_to_update_seats_type'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorSeatsDistributionUpdate, seatsDistributionUpdateResult, showNotification, t]);

  return [
    { seatsDistributionUpdateResult, loadingSeatsDistributionUpdate, errorSeatsDistributionUpdate },
    updateSeatTypeDistribution,
  ];
};

export default useScreeningUpdateSeatTypeDistribution;
