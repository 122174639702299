import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

const useResetPassword = () => {
  const [
    { result: resultResetPassword, loading: loadingResetPassword, error: errorResetPassword },
    resetPassword,
  ] = useApi(`${API_ENDPOINTS.RESET_PASSWORD}?source=0`, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
  });

  return [{ resultResetPassword, loadingResetPassword, errorResetPassword }, resetPassword];
};

export default useResetPassword;
