import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { usePath } from 'utils/hooks';

const useFilm = id => {
  const path = usePath(`films/${id}`);
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();
  const [{ result: film, loading, error }, getFilm] = useApi(path);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('film_fetch_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t, id]);

  return [{ film, loading, error }, getFilm];
};

export default useFilm;
