import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

const path = API_ENDPOINTS.VENUES;

const useVenueUpdateSeats = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.VENUES);
  const { showNotification } = useRoot();

  const [{ result: updateSeatsResult, loading: loadingCreateSeats, error }, updateSeats] = useApi(
    `${path}/${id}/seats`,
    {
      initialFetch: false,
      verb: HTTP_VERBS.PUT,
    }
  );

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('seats_update_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (updateSeatsResult) {
      showNotification({
        message: t('seats_update_success'),
      });
    }
  }, [updateSeatsResult, showNotification, t]);

  return [{ updateSeatsResult, loadingCreateSeats, error }, updateSeats];
};

export default useVenueUpdateSeats;
