import { useEffect, useState } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';

const useSeatTypes = query => {
  const queryString = `${API_ENDPOINTS.SEAT_TYPES}?Name=${query.search}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SEAT_TYPES);
  const { showNotification } = useRoot();
  const [path, setPath] = useState(queryString);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  const [{ result: resultSeatTypes, loading: loadingSeatTypes, error: errorSeatTypes }, getSeatTypes] = useApi(path);

  useEffect(() => {
    if (errorSeatTypes) {
      showNotification(
        {
          message: t('alert_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorSeatTypes, showNotification, t]);

  return [{ resultSeatTypes, loadingSeatTypes, errorSeatTypes }, getSeatTypes];
};

export default useSeatTypes;
