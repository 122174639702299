import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, HTTP_VERBS } from 'utils/constants';

const useSyncAccreditations = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.ACCREDITATION_LIST);
  const { showNotification } = useRoot();
  const path = '/customer-accreditations/sync/background';

  const [
    { result: syncAccreditationsStarted, loading: loadingStartSyncAccreditations, error: errorStartSyncAccreditations },
    syncAccreditations,
  ] = useApi(path, { initialFetch: false, verb: HTTP_VERBS.POST });

  useEffect(() => {
    if (errorStartSyncAccreditations) {
      showNotification(
        {
          message: t('alert_sync_accreditations_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorStartSyncAccreditations, showNotification, t]);

  useEffect(() => {
    if (syncAccreditationsStarted) {
      showNotification({
        message: t('alert_sync_accreditations_success'),
      });
    }
  }, [syncAccreditationsStarted, showNotification, t]);

  return [
    { syncAccreditationsStarted, loadingStartSyncAccreditations, errorStartSyncAccreditations },
    syncAccreditations,
  ];
};

export default useSyncAccreditations;
