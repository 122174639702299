import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, API_QUERY } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { usePath } from 'utils/hooks';

const useUsers = (query = API_QUERY.usersAll) => {
  const queryString = `${API_ENDPOINTS.USERS}?Term=${query.search}&RoleIds=${query.roles.valueOf()}&IsLocked=${
    query.isLocked
  }&IsBlocked=${query.isBlocked}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${
    query.retrieveAll
  }&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const path = usePath(queryString);

  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);
  const { showNotification } = useRoot();

  const [{ result, loading, error }, getUsers] = useApi(path);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('users_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ result, loading, error }, getUsers];
};

export default useUsers;
