import { useEffect, useState } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { HTTP_VERBS } from 'utils/constants';

const useScreeningUpdate = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();
  const [path, setPath] = useState(`${API_ENDPOINTS.SEAT_TYPES}/${id}`);

  useEffect(() => {
    setPath(`screenings/${id}/sales`);
  }, [id]);

  const [
    { result: resultUpdateScreening, loading: loadingUpdateScreening, error: errorUpdateScreening },
    updateScreening,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorUpdateScreening) {
      showNotification(
        {
          message: t('alert_screening_update_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorUpdateScreening, showNotification, t]);

  useEffect(() => {
    if (resultUpdateScreening) {
      showNotification({
        message: t('alert_screening_update_success'),
      });
    }
  }, [resultUpdateScreening, showNotification, t]);

  return [{ resultUpdateScreening, loadingUpdateScreening, errorUpdateScreening }, updateScreening];
};

export default useScreeningUpdate;
