import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { API_ENDPOINTS } from 'utils/constants';

const path = API_ENDPOINTS.VENUES;

const useVenueSeats = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.VENUES);

  const { showNotification } = useRoot();
  const [{ result: seats, loading: loadingSeats, error }, getSeats] = useApi(`${path}/${id}/seats`);
  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('failed_to_fetch_venue_seats'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ seats, loadingSeats }, getSeats];
};

export default useVenueSeats;
