import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { matchRoute } from 'utils/routes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LOCALIZATION_PAGES } from 'utils/constants';
import { getUser } from 'redux/reducers/sessionReducer';
import { isAllowed } from 'utils/helpers';

const SideMenuLink = ({ link }) => {
  const user = useSelector(getUser);
  const { config, path } = link;
  const { sideMenu } = config;
  const { activeLink, label } = sideMenu;
  let { isDisabled } = sideMenu;

  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);

  const history = useHistory();
  const currentRoute = matchRoute(history.location.pathname);
  const isActive = currentRoute.config && currentRoute.config.sideMenu.activeLink === activeLink;
  const activeStyle = { backgroundColor: 'var(--primary)', color: 'var(--white)' };
  if (link.allowIf) {
    isDisabled = !isAllowed(link.allowIf, { user });
  }

  return (
    <div>
      {/* Disabled Link */}
      {isDisabled ? <DisabledLink>{t(label)}</DisabledLink> : null}

      {/* Active Link*/}
      {isActive && !isDisabled ? (
        <ActiveLink to={path} activeStyle={activeStyle}>
          {t(label)}
        </ActiveLink>
      ) : null}

      {/* Regular Link*/}
      {!isActive && !isDisabled ? (
        <Link to={path} activeStyle={activeStyle}>
          {t(label)}
        </Link>
      ) : null}
    </div>
  );
};

const Link = styled(NavLink)`
  cursor: pointer;
  padding-bottom: calc(0.5rem + 2px);
  font-size: 14px;
  line-height: 2.8;
  letter-spacing: normal;
  color: var(--white);
  padding: 0 10px;
  margin-bottom: 4px;
  border-radius: 4px;
  display: block;

  :hover {
    background-color: var(--primary);
    color: var(--white);
  }
`;

// in our case for example /profile should match User Management on the side menu, we need this.. ugh
const ActiveLink = styled(Link)`
  background-color: var(--primary);
`;

const DisabledLink = styled.div`
  padding-bottom: calc(0.5rem + 2px);
  font-size: 14px;
  line-height: 2.86;
  letter-spacing: normal;
  color: var(--secondary);
  padding: 0 10px;
  margin-bottom: 4px;
  border-radius: 4px;
  display: block;
`;

export default SideMenuLink;
