import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input as ReactstrapInput } from 'reactstrap';
import styled from 'styled-components';

import { ErrorMessage, PasswordToggle } from 'components/Form';
import { Label } from 'components/Typography';

const Input = ({ width = 'auto', label = '', validationMessage, togglePassword, type = 'text', ...props }) => {
  const [inputType, setInputType] = useState(type);
  return (
    <InputWrapper>
      {label && <Label>{label}</Label>}
      <InputComponent width={width} {...props} type={inputType} />
      {togglePassword && <PasswordToggle type={inputType} setInputType={setInputType} />}
      {validationMessage && <ErrorMessage message={validationMessage} />}
    </InputWrapper>
  );
};

Input.propTypes = {
  width: PropTypes.string || PropTypes.number,
  label: PropTypes.string,
  validationMessage: PropTypes.string,
  togglePassword: PropTypes.bool,
};

const InputWrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const InputComponent = styled(ReactstrapInput)`
  width: 100%;
  height: 38px;
  font-size: 14px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default Input;
