import React from 'react';
import styled from 'styled-components';

const Text = ({ children, color = 'dark', ...rest }) => (
  <Wrapper color={color} {...rest}>
    {children}
  </Wrapper>
);

const Wrapper = styled.p`
  margin: 0.6rem 0;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ color }) => `var(--${color})`};
`;

export default Text;
