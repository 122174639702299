import { useEffect, useState } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { API_ENDPOINTS } from 'utils/constants';

const useAccreditationPurchaseHistory = (query, id) => {
  const queryString = `${API_ENDPOINTS.CUSTOMER_ACCREDITATIONS}/${id}/purchase-history?Term=${query.name}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const [path, setPath] = useState(queryString);
  const [{ result: purchaseHistory, loading }, getPurchaseHistory] = useApi(path);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  return [{ purchaseHistory, loading }, getPurchaseHistory];
};

export default useAccreditationPurchaseHistory;
