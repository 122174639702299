import React from 'react';
import styled from 'styled-components';
import { Container as ReacstrapContainer } from 'reactstrap';

const Content = props => <Wrapper {...props}>{props.children}</Wrapper>;

const Wrapper = styled(ReacstrapContainer)`
  padding: 32px;
  margin: 0;
  max-width: 1440px;
`;

export default Content;
