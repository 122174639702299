import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, HTTP_VERBS } from 'utils/constants';

const useBallotingScreeningsUpdate = id => {
  const path = `${API_ENDPOINTS.BALLOTING_CATEGORIES}/${id}/screenings`;
  const { t } = useTranslation(LOCALIZATION_PAGES.BALLOTING);
  const { showNotification } = useRoot();
  const [
    {
      result: resultUpdateBallotingScreenings,
      loadingUpdateBallotingScreenings,
      error: errorUpdateBallotingScreenings,
      setResult: setResultUpdateBallotingScreenings,
    },
    updateBallotingScreenings,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorUpdateBallotingScreenings)
      showNotification(
        {
          message: t('balloting_category_screenings_update_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
  }, [errorUpdateBallotingScreenings, showNotification, t]);

  return [
    {
      resultUpdateBallotingScreenings,
      loadingUpdateBallotingScreenings,
      errorUpdateBallotingScreenings,
      setResultUpdateBallotingScreenings,
    },
    updateBallotingScreenings,
  ];
};

export default useBallotingScreeningsUpdate;
