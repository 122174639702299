export const TOKEN_KEY = 'JWT-TOKEN';
export const REFRESH_TOKEN_KEY = 'REFRESH-TOKEN';
export const ACCESS_TOKEN_KEY = 'ACCESS-TOKEN';
export const LANG_KEY = 'CURRENT_LANGUAGE';
export const USER = 'CURRENT_USER';

export const EXTERNAL_SALE_NAME = 'Online';

export const HTTP_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  OK: 200,
};

export const NOTIFICATION_DISMISS_TIMEOUT = 4000;

export const ALERT_VARIANTS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
};

export const LOCALIZATION_PAGES = {
  LOGIN: 'login',
  COMMON: 'common',
  CHANGE_PASSWORD: 'change_password',
  FORGOTTEN_PASSWORD: 'forgotten_password',
  ROLES: 'roles',
  HEADER: 'header',
  USER_PROFILE: 'user_profile',
  TICKET_TYPES: 'ticket_types',
  EVENTIVAL_ACCREDITATIONS: 'eventival_accreditations',
  USER_LIST: 'user_list',
  ACCREDITATION_PROFILE: 'accreditation_profile',
  ACCREDITATION_LIST: 'accreditation_list',
  SEAT_TYPES: 'seat_types',
  VENUES: 'venues',
  SCREENINGS: 'screenings',
  DISCOUNTS: 'discounts',
  SETTINGS: 'settings',
  SALES_POLICY: 'sales_policy',
  ACCREDITATION_PURCHASE_HISTORY: 'accreditation_purchase_history',
  FILMS: 'films',
  REPORTING: 'reporting',
  BALLOTING: 'balloting',
  RANDOM_WINNER: 'random_winner',
};

export const LANGUAGES = [
  {
    id: 1,
    value: 'ENG',
    NAME: 'English',
    LANGUAGE_CODE: 'en-us',
    LCID: 'en-us',
    iconPath: `${process.env.PUBLIC_URL}/assets/icons/uk.svg`,
    DEFAULT: true,
    ISO_CODE: 'en',
  },
  {
    id: 2,
    value: 'BiH',
    NAME: 'Bosnian',
    LANGUAGE_CODE: 'bs-BA',
    LCID: 'bs-BA',
    iconPath: `${process.env.PUBLIC_URL}/assets/icons/bh.svg`,
    ISO_CODE: 'bs',
  },
];

export const DEFAULT_PAGE_SIZE = 10;

export const ROLES = {
  SuperAdmin: 'SuperAdmin',
  Admin: 'Admin',
  User: 'User',
};

export const HTTP_VERBS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const DEBOUNCE_TIMEOUT = 300;

export const SORT_ORDER = {
  ASC: 0,
  DESC: 1,
};

export const API_ENDPOINTS = {
  TICKET_TYPES: 'ticket-types',
  USERS: 'users',
  ROLES: 'roles',
  SEAT_TYPES: 'seat-types',
  VENUES: 'venues',
  ACCREDITATIONS: 'accreditations',
  TICKET_TYPES_SELECTOR: 'ticket-types/selectors',
  CUSTOMER_ACCREDITATIONS: 'customer-accreditations',
  AUTH_TOKEN: 'auth/token',
  REFRESH_AUTH_TOKEN: 'auth/token/refresh',
  RESET_PASSWORD: 'accounts/password/reset',
  ACCREDITATION_GROUPS: 'ticket-types/accreditation-groups',
  SCREENINGS: 'screenings',
  LANGUAGES: 'common/languages',
  LANGUAGES_ASSIGNED_TO_FILMS: 'common/languages-assigned-to-films',
  DISCOUNTS: 'discounts',
  EDITIONS: 'editions',
  PROGRAMS: 'films/programs',
  REPORTING: 'reporting',
  EXTERNAL_USERS: '/external/users/admin',
  TICKETS: '/tickets',
  ALL_COUNTRIES: 'common/countries',
  BALLOTING_CATEGORIES: 'balloting-categories',
  VOTES: 'votes',
  VOTES_WINNER: 'votes/winner',
  BALLOTING_CATEGORIES_STATS: '/balloting-categories/basic-stats',
  REAL_TIME_STATUS: categoryId => `films/categories/${categoryId}/ratings`,
};

export const SCREENINGS_STATUSES = {
  ENDED: 'ended',
  SCHEDULED: 'scheduled',
};

export const IS_SALE_ENABLED = {
  YES: 'yes',
  NO: 'no',
};

export const CURRENCY = {
  KM: 'KM',
  BAM: 'BAM',
};

const Query = {
  shared: {
    search: '',
    term: '',
    name: '',
    sortBy: '',
    sortOrder: 0,
    includes: '',
  },
  defaultAll: {
    pageSize: '',
    page: '',
    retrieveAll: true,
    includeCount: '',
  },
  default: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
    retrieveAll: false,
    includeCount: true,
  },
  custom: {
    roles: {
      name: '',
    },
    seatTypes: {
      name: '',
    },
    users: {
      includes: 'UserRoles.Role',
      roles: [],
      isBlocked: '',
      isLocked: '',
    },
    accreditations: {
      name: '',
    },
    personalAccreditations: {
      eventivalAccreditations: [],
      ticketingSystemTypes: [],
      includes: 'Customer&Includes=Accreditation.TicketType',
    },
    ticketTypes: {
      name: '',
      includes: 'TicketTypeSelector&Includes=Accreditations',
    },
    screenings: {
      venueId: '',
      languageId: '',
      programId: '',
      startDate: '',
      endDate: '',
      status: '',
      lowerCapacityLimit: '',
      upperCapacityLimit: '',
    },
    unassignedBallotingScreenings: {
      venueId: '',
      languageId: '',
      programIds: [],
    },
    filmScreenings: {
      includes: '',
    },
    discounts: {
      code: '',
    },
    editions: {
      eventivalEditionName: '',
    },
    accreditationPurchaseHistory: {},
    reporting: {
      programs: [],
      venues: [],
      films: [],
      screenings: [],
      screeningCodes: [],
      eventivalAccreditationTypes: [],
      paymentTypes: [],
      saleTypes: [],
      ticketTypes: [],
      seatTypes: [],
      users: [],
      printDateStart: '',
      printDateEnd: '',
      screeningDateStart: '',
      screeningDateEnd: '',
      discounts: [],
      distributors: [],
      screeningStatus: '',
    },
    customerAwards: {
      pageSize: 50,
    },
  },

  generateQuerries: function() {
    let querries = {
      default: {
        ...this.default,
        ...this.shared,
      },
      defaultAll: {
        ...this.defaultAll,
        ...this.shared,
      },
    };
    Object.keys(this.custom).forEach(x => {
      querries[x] = {
        ...this.shared,
        ...this.default,
        ...this.custom[x],
      };
      querries[`${x}All`] = {
        ...this.shared,
        ...this.defaultAll,
        ...this.custom[x],
      };
    });

    return querries;
  },
};

export const MARKINGS = {
  letters: { id: 0, name: 'Letters', label: 'letters' },
  numbers: { id: 1, name: 'Numbers', label: 'numbers' },
};

export const ORIENTATION = {
  normal: { id: 0, name: 'NORMAL' },
  reverse: { id: 1, name: 'REVERSE' },
};

export const COLUMNS_ORIENTATION_DROPDOWN_ITEMS = [
  { id: 0, name: 'left_to_right' },
  {
    id: 1,
    name: 'right_to_left',
  },
];

export const ROWS_ORIENTATION_DROPDOWN_ITEMS = [
  { id: 0, name: 'ascending' },
  {
    id: 1,
    name: 'descending',
  },
];

export const API_QUERY = Query.generateQuerries();

export const SEATING_TYPES = {
  reserved: 'RESERVED',
  distribution: 'DISTRIBUTION',
};

export const SALE_END_TIME_STATUSES = {
  ON: 'ON_SCREENING_START',
  BEFORE: 'BEFORE_SCREENING_START',
  AFTER: 'AFTER_SCREENING_START',
};

export const SALE_END_TIME_SATUS_SELECT = [
  { id: SALE_END_TIME_STATUSES.ON, name: 'On', label: 'sale_end_time_on_screening' },
  { id: SALE_END_TIME_STATUSES.BEFORE, name: 'Before', label: 'sale_end_time_before_screening' },
  { id: SALE_END_TIME_STATUSES.AFTER, name: 'After', label: 'sale_end_time_after_screening' },
];

export const CONTACTS_TYPES = {
  PRODUCTION: 'Production Company',
  CONTACT: 'Public Contact',
  CO_PRODUCTION: 'Co-Production Company',
};

export const BALLOTING_CATEGORY_STATUSES = {
  PENDING: { name: 'Pending', label: 'balloting_cat_status_pending' },
  STARTED: { name: 'Started', label: 'balloting_cat_status_started' },
  COMPLETED: { name: 'Completed', label: 'balloting_cat_status_completed' },
  NO_CONTEST: { name: 'No Contest', label: 'balloting_cat_status_no_contest' },
};

export const PERMISSIONS_CATEGORIES = [
  { name: 'Hangfire', short: 'Hangfire' },
  { name: 'Users', short: 'Users' },
  { name: 'Roles', short: 'Roles' },
  { name: 'Venues', short: 'Venues' },
  { name: 'Screenings', short: 'Screenings' },
  { name: 'Settings', short: 'Settings' },
  { name: 'Discounts', short: 'Discounts' },
  { name: 'Cart', short: 'Cart' },
  { name: 'Reservation', short: 'Reservation' },
  { name: 'Print Log', short: 'PrintLog' },
  { name: 'Seat Types', short: 'SeatTypes' },
  { name: 'Ticket Types', short: 'TicketTypes' },
  { name: 'Balloting Categories', short: 'BallotingCategories' },
  { name: 'Accreditations', short: 'Accreditations' },
  { name: 'Reporting', short: 'Reporting' },
];

export const SPONSORS = {
  BH_TELECOM: 'BH Telecom',
};

export const RANDOM_NUMBERS =
  '12345678998745654123789987456541231234567899874565412312345678998745654123678998745654123789987456541231234567899874565412312345678998745654123567899874565412378998745654123123456789987456541231234567899874565412356789987456541237899874565412312345678998745654123123456789987456541231234567899874565412378998745654123123456789987456541231234567899874565412367899874565412378998745654123123456789987456541231234567899874565412356789987456541237899874565412312345678998745654123123456789987456541235678998745654123789987456541231234567899874565412312345678998745654123';
