import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { HTTP_VERBS } from 'utils/constants';

const useScreeningUpdateCustomReservedSeating = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();
  const path = `${API_ENDPOINTS.SCREENINGS}/${id}/seats/reserved/custom`;

  const [
    {
      result: updateScreeningCustomReservedSeatingResult,
      loading: loadingScreeningScreeningCustomReservedSeating,
      error: errorScreeningScreeningCustomReservedSeating,
      setResult: setUpdateScreeningCustomReservedSeatingResult,
    },
    updateScreeningCustomReservedSeating,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorScreeningScreeningCustomReservedSeating) {
      showNotification(
        {
          message: t('change_seating_error'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorScreeningScreeningCustomReservedSeating, showNotification, t]);

  useEffect(() => {
    if (updateScreeningCustomReservedSeatingResult) {
      showNotification({
        message: t('change_seating_confirm'),
      });
    }
  }, [updateScreeningCustomReservedSeatingResult, showNotification, t]);

  return [
    {
      updateScreeningCustomReservedSeatingResult,
      loadingScreeningScreeningCustomReservedSeating,
      errorScreeningScreeningCustomReservedSeating,
      setUpdateScreeningCustomReservedSeatingResult,
    },
    updateScreeningCustomReservedSeating,
  ];
};

export default useScreeningUpdateCustomReservedSeating;
