import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, HTTP_VERBS } from 'utils/constants';

const path = API_ENDPOINTS.BALLOTING_CATEGORIES;

const useCreateBallotingCategory = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.BALLOTING);
  const { showNotification } = useRoot();
  const [{ result, loading, error }, createCategory] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('category_create_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('category_create_success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ result, loading, error }, createCategory];
};

export default useCreateBallotingCategory;
