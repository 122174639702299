import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { HTTP_VERBS } from 'utils/constants';

const useVenueUpdateSeatingType = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.VENUES);
  const { showNotification } = useRoot();
  const path = `${API_ENDPOINTS.VENUES}/${id}/seating-type`;

  const [
    {
      result: updateVenueSeatingTypeResult,
      loading: loadingUpdateVenueSeatingType,
      error: errorVenueSeatingType,
      setResult: setUpdateVenueSeatingResult,
    },
    updateVenueSeatingType,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorVenueSeatingType) {
      showNotification(
        {
          message: t('change_seating_error'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorVenueSeatingType, showNotification, t]);

  useEffect(() => {
    if (updateVenueSeatingTypeResult) {
      showNotification({
        message: t('change_seating_confirm'),
      });
    }
  }, [updateVenueSeatingTypeResult, showNotification, t]);

  return [
    { updateVenueSeatingTypeResult, loadingUpdateVenueSeatingType, errorVenueSeatingType, setUpdateVenueSeatingResult },
    updateVenueSeatingType,
  ];
};

export default useVenueUpdateSeatingType;
