import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup as ReactstrapInputGroup, InputGroupAddon, Input as ReactstrapInput } from 'reactstrap';
import styled from 'styled-components';

import { ErrorMessage } from 'components/Form';
import ColorPicker from 'components/ColorPicker/ColorPicker';
import { Label } from 'components/Typography';

import { useToggle } from 'utils/hooks';

const Input = ({ width = 'auto', label = '', validationMessage, onChange, value, ...rest }) => {
  const [displayColorPicker, toggleDisplayColorPicker] = useToggle(false);

  const handleChange = color => {
    onChange(color.hex);
  };

  return (
    <>
      <InputWrapper>
        <Label>{label}</Label>
        <InputGroup onClick={toggleDisplayColorPicker}>
          <ColorSwatchWrapper addonType="prepend">
            <ColorSwatch selectedColor={value} />
          </ColorSwatchWrapper>
          <InputComponent width={width} {...rest} value={value} readOnly />
        </InputGroup>
        {validationMessage && <ErrorMessage message={validationMessage} />}
      </InputWrapper>
      <ColorPicker
        color={value}
        disableAlpha={true}
        display={displayColorPicker}
        handleClose={toggleDisplayColorPicker}
        onChange={handleChange}
      />
    </>
  );
};

Input.propTypes = {
  width: PropTypes.string || PropTypes.number,
  label: PropTypes.string,
  selectedColor: PropTypes.string,
};

const InputWrapper = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const InputComponent = styled(ReactstrapInput)`
  width: 100%;
  height: 38px;
  font-size: 14px;
  border-left: 0;
  padding-left: 0;
  &:focus {
    box-shadow: 0 0 0 0;
    border: 1px solid #ced4da;
    border-left: 0;
  }
  &:read-only {
    background-color: var(--white);
  }
  cursor: pointer !important;
`;

const InputGroup = styled(ReactstrapInputGroup)`
  cursor: pointer !important;
`;

const ColorSwatchWrapper = styled(InputGroupAddon)`
  position: relative;
  width: 48px;
  border: 1px solid #ced4da;
  border-right: 0;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
`;

const ColorSwatch = styled.div`
  background-color: ${({ selectedColor }) => selectedColor};
  height: 21px;
  width: 21px;
  border-radius: 5px;
  position: absolute;
  top: 8px;
  left: 12px;
`;
export default Input;
