import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useBlockExternalUser = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);

  const [path, setPath] = useState(`${API_ENDPOINTS.EXTERNAL_USERS}/${id}/block-status`);

  useEffect(() => {
    setPath(`${API_ENDPOINTS.EXTERNAL_USERS}/${id}/block-status`);
  }, [id]);

  const { showNotification } = useRoot();
  const [{ result, loading, error }, blockUser] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ result, loading }, blockUser];
};

export default useBlockExternalUser;
