import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, API_QUERY } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { usePath } from 'utils/hooks';

const useRoles = (query = API_QUERY.rolesAll) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.ROLES);
  const { showNotification } = useRoot();

  const queryString = `${API_ENDPOINTS.ROLES}?Name=${query.name}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const path = usePath(queryString);

  const [{ result, loading: loadingRoles, error: errorRoles }, getRoles] = useApi(path);

  useEffect(() => {
    if (errorRoles) {
      showNotification(
        {
          message: t('alert_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorRoles, showNotification, t]);

  return [{ result, loadingRoles, errorRoles }, getRoles];
};

export default useRoles;
