import React from 'react';
import { Redirect } from 'react-router-dom';

import { getStore } from 'components/App/App';
import { getUser } from 'redux/reducers/sessionReducer';
import AccessDenied from 'components/AccessDenied/AccessDenied';
import { isAllowed } from 'utils/helpers';
import routes from 'utils/routes';

const Authorization = (WrappedComponent, allowedPermissions) =>
  class WithAuthorization extends React.Component {
    render() {
      const state = getStore().getState();
      const user = getUser(state);

      if (!user) return <Redirect to={routes.LOGIN.path} />;

      if (isAllowed(allowedPermissions, { user })) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <AccessDenied />;
      }
    }
  };

export default Authorization;
