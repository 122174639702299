import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { API_ENDPOINTS } from 'utils/constants';

const path = API_ENDPOINTS.SCREENINGS;

const useScreeningSaveSeats = (id, showSuccessMsg = true) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();

  const [{ result: createSeatsResult, loading: loadingCreateSeats, error }, createSeats] = useApi(
    `${path}/${id}/seats`,
    {
      initialFetch: false,
      verb: HTTP_VERBS.POST,
    }
  );

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('seats_create_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (createSeatsResult && showSuccessMsg) {
      showNotification({
        message: t('seats_create_success'),
      });
    }
  }, [createSeatsResult, showNotification, t, showSuccessMsg]);

  return [{ createSeatsResult, loadingCreateSeats, error }, createSeats];
};

export default useScreeningSaveSeats;
