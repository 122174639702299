import React from 'react';
import styled from 'styled-components';

import SideMenuLink from 'components/Layout/SideMenuLink';

const SideMenuLinks = ({ titleLabel, links }) => {
  return (
    <Content>
      <Title>{titleLabel}</Title>
      {/* first sort side menu links by order defined in config, then map each link*/}
      {links
        .sort((a, b) => a.config.sideMenu.order - b.config.sideMenu.order)
        .map((link, i) => (
          <SideMenuLink key={i} link={link} />
        ))}
    </Content>
  );
};

const Title = styled.div`
  color: var(--secondary);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  padding: 8px 10px;
`;

const Content = styled.div`
  :first-child {
    padding-bottom: 30px;
  }
`;

export default SideMenuLinks;
