import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const Dropdown = ({ label = '', items, ...rest }) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);
  return (
    <Input type="select" label={label} value="" {...rest}>
      <option value="" disabled>
        {t('select_default_item')}
      </option>
      {items.map(m => {
        return (
          <option key={m.name} value={m.id}>
            {m?.label ? t(`${m.label}`) : t(`${m.name}`)}
          </option>
        );
      })}
    </Input>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default Dropdown;
