import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const usePrograms = () => {
  const path = `${API_ENDPOINTS.PROGRAMS}`;
  const { t } = useTranslation(LOCALIZATION_PAGES.REPORTING);
  const { showNotification } = useRoot();
  const [{ result: programs, loading: programsLoading, error }, getPrograms] = useApi(path);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('programs_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ programs, programsLoading, error }, getPrograms];
};

export default usePrograms;
