import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';

const path = id => `${API_ENDPOINTS.BALLOTING_CATEGORIES}/${id}`;

const useUpdateBallotingCategory = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.BALLOTING);
  const { showNotification } = useRoot();
  const [{ result, loading, error }, updateCategory] = useApi(path(id), {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('category_update_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('category_update_success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ result, loading }, updateCategory];
};

export default useUpdateBallotingCategory;
