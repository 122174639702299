import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, HTTP_VERBS, LOCALIZATION_PAGES } from 'utils/constants';

const useExternalUserResetPassword = () => {
  const { showNotification } = useRoot();
  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);
  const [{ result: resultResetPass, loading: loadingResetPass, error: errorResetPass }, resetPass] = useApi(
    'accounts/external/password/reset',
    {
      initialFetch: false,
      verb: HTTP_VERBS.POST,
    }
  );
  useEffect(() => {
    if (resultResetPass) {
      showNotification({
        message: t('reset_password_success'),
      });
    }
  }, [resultResetPass, showNotification, t]);

  useEffect(() => {
    if (errorResetPass) {
      showNotification(
        {
          message: t('reset_password_failed'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorResetPass, showNotification, t]);

  return [{ resultResetPass, loadingResetPass, errorResetPass }, resetPass];
};

export default useExternalUserResetPassword;
