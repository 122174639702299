import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';

const useRealTimeStatus = (categoryId, query) => {
  const queryString = `${API_ENDPOINTS.REAL_TIME_STATUS(categoryId)}?Term=${query.term}&PageSize=${
    query.pageSize
  }&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${
    query.sortBy
  }&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const { t } = useTranslation(LOCALIZATION_PAGES.BALLOTING);
  const { showNotification } = useRoot();
  const [path, setPath] = useState(queryString);
  const [{ result, loading, error }, getRealStatus] = useApi(path);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  useEffect(() => {
    if (error)
      showNotification(
        {
          message: t('real_time_status_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
  }, [error, showNotification, t]);

  return [{ result, loading }, getRealStatus];
};

export default useRealTimeStatus;
