import { useEffect } from 'react';
import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

const path = 'editions';

const useCreateEdition = edition => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SETTINGS);
  const { showNotification } = useRoot();

  const [
    { result: createEditionResult, loading: loadingCreateEdition, error: errorCreateEdition },
    createEdition,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.POST,
    data: edition,
  });

  useEffect(() => {
    if (errorCreateEdition) {
      showNotification(
        {
          message: t('alert_create_edition_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorCreateEdition, showNotification, t]);

  useEffect(() => {
    if (createEditionResult) {
      showNotification({
        message: t('alert_create_edition_success'),
      });
    }
  }, [createEditionResult, showNotification, t]);

  return [{ createEditionResult, loadingCreateEdition, errorCreateEdition }, createEdition];
};

export default useCreateEdition;
