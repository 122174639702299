import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, HTTP_VERBS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useLoadEdition = id => {
  const path = `${API_ENDPOINTS.EDITIONS}/${id}/load`;
  const { t } = useTranslation(LOCALIZATION_PAGES.SETTINGS);
  const { showNotification } = useRoot();
  const [{ result: resultLoadEdition, loading: loadingLoadEdition, error: errorLoadEdition }, loadEdition] = useApi(
    path,
    {
      initialFetch: false,
      verb: HTTP_VERBS.PATCH,
    }
  );

  useEffect(() => {
    if (errorLoadEdition) {
      showNotification(
        {
          message: t('alert_update_editions_fail'),
        },

        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorLoadEdition, showNotification, t]);

  useEffect(() => {
    if (resultLoadEdition) {
      showNotification({
        message: t('alert_update_editions_success'),
      });
    }
  }, [resultLoadEdition, showNotification, t]);

  return [{ resultLoadEdition, loadingLoadEdition }, loadEdition];
};

export default useLoadEdition;
