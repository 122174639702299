import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert as BootstrapAlert } from 'reactstrap';

import { ALERT_VARIANTS } from 'utils/constants';
import { NOTIFICATION_DISMISS_TIMEOUT } from 'utils/constants';

function Alert({ children, variant, onClose, id, duration, ...rest }) {
  const handleClose = () => {
    if (onClose) {
      onClose(id);
    }
  };

  return (
    <CustomAlert onClose={handleClose} color={variant} {...rest}>
      {children}
    </CustomAlert>
  );
}

const CustomAlert = styled(BootstrapAlert)`
  float: right;
`;

Alert.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.string,
  duration: PropTypes.number,
};

Alert.defaultProps = {
  variant: ALERT_VARIANTS.SUCCESS,
  duration: NOTIFICATION_DISMISS_TIMEOUT,
  id: undefined,
  onClose: undefined,
};

export default Alert;
