import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useVenues = query => {
  const queryString = `/venues?Name=${query.search}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const { t } = useTranslation(LOCALIZATION_PAGES.VENUES);
  const [path, setPath] = useState(queryString);

  useEffect(() => {
    setPath(queryString);
  }, [queryString]);

  const { showNotification } = useRoot();
  const [{ result: venues, loading: loadingVenues, error }, getVenues] = useApi(path);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('failed_to_fetch'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ venues, loadingVenues, error }, getVenues];
};

export default useVenues;
