import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { LOCALIZATION_PAGES } from 'utils/constants';
import { API_ENDPOINTS, HTTP_CODES } from 'utils/constants';

const path = API_ENDPOINTS.SCREENINGS;

const useScreeningUpdateCode = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();

  const [{ result: resultScreeningCode, loading: loadingScreeningCode, error }, updateScreeningCode] = useApi(
    `${path}/${id}/code`,
    {
      initialFetch: false,
      verb: HTTP_VERBS.PUT,
    }
  );

  useEffect(() => {
    if (error?.status === HTTP_CODES.BAD_REQUEST) {
      showNotification(
        {
          message: t('code_already_exists'),
        },
        ALERT_VARIANTS.INFO
      );
    } else if (error) {
      showNotification(
        {
          message: t('code_save_failed'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (resultScreeningCode) {
      showNotification({
        message: t('code_save_success'),
      });
    }
  }, [resultScreeningCode, showNotification, t]);

  return [{ resultScreeningCode, loadingScreeningCode, error }, updateScreeningCode];
};

export default useScreeningUpdateCode;
