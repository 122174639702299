import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS, API_QUERY } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { usePath } from 'utils/hooks';

const useEditions = (query = API_QUERY.editionsAll) => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SETTINGS);
  const { showNotification } = useRoot();

  const queryString = `${API_ENDPOINTS.EDITIONS}?Name=${query.name}&EventivalEditionName=${query.eventivalEditionName}&PageSize=${query.pageSize}&Page=${query.page}&RetrieveAll=${query.retrieveAll}&IncludeCount=${query.includeCount}&SortBy=${query.sortBy}&SortOrder=${query.sortOrder}&Includes=${query.includes}`;
  const path = usePath(queryString);

  const [
    { result: editions, loading: loadingEditions, error: errorEditions, setResult: setEditions },
    getEditions,
  ] = useApi(path);

  useEffect(() => {
    if (errorEditions) {
      showNotification(
        {
          message: t('alert_fetch_edition_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorEditions, showNotification, t]);

  return [{ editions, loadingEditions, errorEditions, setEditions }, getEditions];
};

export default useEditions;
