import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { useRoot } from 'context/RootContext';
import { HTTP_VERBS } from 'utils/constants';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';

const useUpdateAccreditationLimit = id => {
  const path = `${API_ENDPOINTS.ACCREDITATIONS}/${id}/limits`;
  const { t } = useTranslation(LOCALIZATION_PAGES.ACCREDITATION_PROFILE);
  const { showNotification } = useRoot();
  const [{ result, loading, error }, updateAccreditationLimit] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('accreditation_update_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  useEffect(() => {
    if (result) {
      showNotification({
        message: t('accreditation_update_success'),
      });
    }
  }, [result, showNotification, t]);

  return [{ result, loading }, updateAccreditationLimit];
};

export default useUpdateAccreditationLimit;
