export default {
  CanViewUsers: 'Permission.Users.View',
  CanCreateUsers: 'Permission.Users.Create',
  CanUpdateUsers: 'Permission.Users.Update',
  CanDeleteUsers: 'Permission.Users.Delete',
  CanBlockUsers: 'Permission.Users.Block',

  CanViewRoles: 'Permission.Roles.View',
  CanCreateRoles: 'Permission.Roles.Create',
  CanUpdateRoles: 'Permission.Roles.Update',
  CanDeleteRoles: 'Permission.Roles.Delete',

  CanViewScreenings: 'Permission.Screenings.View',
  CanCreateScreeningsLayout: 'Permission.Screenings.CreateLayout',
  CanUpdateScreenings: 'Permission.Screenings.Update',
  CanDeleteScreeningsLayout: 'Permission.Screenings.DeleteLayout',

  CanViewSeatTypes: 'Permission.SeatTypes.View',
  CanCreateSeatTypes: 'Permission.SeatTypes.Create',
  CanUpdateSeatTypes: 'Permission.SeatTypes.Update',
  CanDeleteSeatTypes: 'Permission.SeatTypes.Delete',

  CanViewTicketTypes: 'Permission.TicketTypes.View',
  CanCreateTicketTypes: 'Permission.TicketTypes.Create',
  CanUpdateTicketTypes: 'Permission.TicketTypes.Update',
  CanDeleteTicketTypes: 'Permission.TicketTypes.Delete',

  CanViewVenues: 'Permission.Venues.View',
  CanCreateVenuesLayout: 'Permission.Venues.CreateLayout',
  CanUpdateVenues: 'Permission.Venues.Update',
  CanDeleteVenuesLayout: 'Permission.Venues.DeleteLayout',

  CanViewSettings: 'Permission.Settings.View',
  CanCreateSettings: 'Permission.Settings.Create',
  CanUpdateSettings: 'Permission.Settings.Update',
  CanDeleteSettings: 'Permission.Settings.Delete',

  CanViewDiscounts: 'Permission.Discounts.View',
  CanCreateDiscounts: 'Permission.Discounts.Create',
  CanUpdateDiscounts: 'Permission.Discounts.Update',
  CanDeleteDiscounts: 'Permission.Discounts.Delete',

  CanViewAccreditations: 'Permission.Accreditations.View',
  CanUpdateTicketPurchases: 'Permission.Accreditations.TicketPurchasesUpdate',
  CanSyncAccreditations: 'Permission.Accreditations.Sync',

  CanViewScreeningsReport: 'Permission.Reporting.ViewScreenings',
  CanViewTicketsReport: 'Permission.Reporting.ViewTickets',
  CanViewExternalUsers: 'Permission.ExternalUsers.View',
  CanResendTicketsForExternalUsers: 'Permission.ExternalUsers.ResendTickets',
  CanUpdateExternalUsers: 'Permission.ExternalUsers.Update',
  CanDeleteExternalUsers: 'Permission.ExternalUsers.Delete',
  CanBlockExternalUsers: 'Permission.ExternalUsers.Block',

  CanViewBalloting: 'Permission.Balloting.View',
  CanUpdateBalloting: 'Permission.Balloting.Create/Update/Delete',
};
