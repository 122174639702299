import { useCallback } from 'react';
import { useImmer } from 'use-immer';
import i18n from 'i18next';

const useThLocales = (t, initialColumns = []) => {
  // column object  { name, label, sortable, sortOrder, sortBy }
  let [columns, setColumns] = useImmer(initialColumns);

  i18n.on('languageChanged', () => {
    setColumns(draft => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].name && draft[i].label) {
          draft[i].name = t(draft[i].label);
        }
      }
    });
  });

  const sortColumns = useCallback(
    (sortBy, sortOrder) => {
      setColumns(draft => {
        draft.forEach(x => (x.sortBy === sortBy ? (x.sortOrder = sortOrder) : (x.sortOrder = null)));
      });
    },
    [setColumns]
  );

  return { columns, sortColumns };
};

export default useThLocales;
