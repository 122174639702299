import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS, LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';

const useExternalUser = id => {
  const path = `${API_ENDPOINTS.EXTERNAL_USERS}/${id}`;
  const { t } = useTranslation(LOCALIZATION_PAGES.USER_PROFILE);

  const { showNotification } = useRoot();
  const [{ result, loading, error }, getExternalUser] = useApi(path);

  useEffect(() => {
    if (error) {
      showNotification(
        {
          message: t('user_fetch_fail'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [error, showNotification, t]);

  return [{ result, loading, error }, getExternalUser];
};

export default useExternalUser;
