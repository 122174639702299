import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DropdownToggle } from 'reactstrap';

import Select from 'components/Select/Select';
import Flexbox from 'components/Flexbox/Flexbox';
import { getLanguage } from 'redux/reducers/sessionReducer';
import { sessionActions } from 'redux/actions';
import { LANGUAGES, LOCALIZATION_PAGES } from 'utils/constants';

const LanguageSelect = ({ ...rest }) => {
  const language = useSelector(getLanguage);
  const dispatch = useDispatch();
  const { t } = useTranslation(LOCALIZATION_PAGES.HEADER);

  const Toggle = () => {
    const langConfig = LANGUAGES.find(l => l.value === language.value);

    return (
      <CustomToggle color="light" block>
        <Icon src={langConfig && langConfig.iconPath} alt={t('flag_icon')} />
        <ToogleValue>{langConfig && langConfig.value}</ToogleValue>
        <Icon src={`${process.env.PUBLIC_URL}/assets/icons/select.svg`} alt={t('select_icon')} />
      </CustomToggle>
    );
  };

  return (
    <LanguageContainer>
      <Select
        width="100%"
        value={language}
        items={LANGUAGES}
        onSelect={lng => dispatch(sessionActions.setLanguage(lng))}
        ToggleElem={Toggle}
        {...rest}
      />
    </LanguageContainer>
  );
};

const LanguageContainer = styled(Flexbox)`
  height: 38px;
  width: 104px;
  align-items: center;
`;

const Icon = styled.img`
  height: 13px;
  width: 13px;
`;

const CustomToggle = styled(DropdownToggle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border: solid 1px #d8dce6;
`;
const ToogleValue = styled.span`
  font-weight: bold;
`;

export default LanguageSelect;
