import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES } from 'utils/constants';

import SideMenuLinks from 'components/Layout/SideMenuLinks';
import routes from 'utils/routes';

// temporary file, svg file not provided by SFF crew
const logoPath = `${process.env.PUBLIC_URL}/sff-logo-white@3x.png`;

const SideMenu = () => {
  const { t } = useTranslation(LOCALIZATION_PAGES.COMMON);

  const menuLinks = [];
  const supportLinks = [];

  Object.values(routes).map(r => {
    if (r.config && r.config.sideMenu) {
      if (r.config.sideMenu.isSupport) {
        supportLinks.push(r);
      } else {
        menuLinks.push(r);
      }
    }
    return null;
  });

  return (
    <SideNavigation>
      <SFFLogo>
        <img src={logoPath} alt="Sarajevo Film Festival" />
      </SFFLogo>
      <Menu>
        <SideMenuLinks titleLabel={t('menu')} links={menuLinks} />
        <SideMenuLinks titleLabel={t('support')} links={supportLinks} />
      </Menu>
      <Footer>
        <p>© Sarajevo Film Festival 2020</p>
        <p className="small">{t('footer')}</p>
      </Footer>
    </SideNavigation>
  );
};

const SideNavigation = styled.div`
  height: 100vh;
  width: 270px;
  background-color: var(--gray);
  color: white;
  position: sticky;
  top: 0;
`;

const SFFLogo = styled.div`
  padding: 38px;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 194px;
    height: 52px;
  }
`;

const Footer = styled.div`
  background-color: var(--gray);
  max-width: 270px;

  position: absolute;
  bottom: 0;
  padding: 30px 30px 10px 30px;
  display: flex;
  flex-wrap: wrap;

  p {
    font-size: 14px;
  }

  p.small {
    font-size: 12px;
    color: #6b6c6f;
  }
`;

const Menu = styled.div`
  padding: 0 30px;
  overflow-y: scroll;
  height: 70vh;

  // scrollbar width
  ::-webkit-scrollbar {
    width: 6px;
  }

  // scrollbar Handle color
  ::-webkit-scrollbar-thumb {
    background: #676767;
  }

  // scrollbar Handle color on hover
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default SideMenu;
