import { useEffect } from 'react';

import useApi from 'utils/hooks/api/useApi';
import { ALERT_VARIANTS } from 'utils/constants';
import { useRoot } from 'context/RootContext';
import { useTranslation } from 'react-i18next';
import { LOCALIZATION_PAGES, API_ENDPOINTS } from 'utils/constants';
import { HTTP_VERBS } from 'utils/constants';

const useScreeningUpdateCustomDistributedSeating = id => {
  const { t } = useTranslation(LOCALIZATION_PAGES.SCREENINGS);
  const { showNotification } = useRoot();
  const path = `${API_ENDPOINTS.SCREENINGS}/${id}/seats/distribution/custom`;

  const [
    {
      result: updateScreeningCustomDistributedSeatingResult,
      loading: loadingScreeningScreeningCustomDistributedSeating,
      error: errorScreeningScreeningCustomDistributedSeating,
      setResult: setUpdateScreeningCustomDistributedSeatingResult,
    },
    updateScreeningCustomDistributedSeating,
  ] = useApi(path, {
    initialFetch: false,
    verb: HTTP_VERBS.PUT,
  });

  useEffect(() => {
    if (errorScreeningScreeningCustomDistributedSeating) {
      showNotification(
        {
          message: t('change_seating_error'),
        },
        ALERT_VARIANTS.DANGER
      );
    }
  }, [errorScreeningScreeningCustomDistributedSeating, showNotification, t]);

  useEffect(() => {
    if (updateScreeningCustomDistributedSeatingResult) {
      showNotification({
        message: t('change_seating_confirm'),
      });
    }
  }, [updateScreeningCustomDistributedSeatingResult, showNotification, t]);

  return [
    {
      updateScreeningCustomDistributedSeatingResult,
      loadingScreeningScreeningCustomDistributedSeating,
      errorScreeningScreeningCustomDistributedSeating,
      setUpdateScreeningCustomDistributedSeatingResult,
    },
    updateScreeningCustomDistributedSeating,
  ];
};

export default useScreeningUpdateCustomDistributedSeating;
