import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';

import { useToggle } from 'utils/hooks';

const Select = ({ open, value, items, onSelect, ToggleElem, ...rest }) => {
  const [isOpen, setIsOpen] = useToggle(open);

  return (
    <CustomDropdown isOpen={isOpen} toggle={setIsOpen} {...rest}>
      <ToggleElem />
      <CustomDropdownMenu right>
        {items.map(item => (
          <DropdownItemBox
            key={item.id}
            onClick={() => onSelect(item)}
            active={value && value.value === item.value}
            disabled={item?.disabled === true}
          >
            <DropdownItemText color={item.color} disabled={item.disabled === true}>
              {item.NAME}
            </DropdownItemText>
          </DropdownItemBox>
        ))}
      </CustomDropdownMenu>
    </CustomDropdown>
  );
};

const CustomDropdown = styled(Dropdown)`
  width: ${({ width }) => (width ? width : '')};
`;

const DropdownItemBox = styled(DropdownItem)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const DropdownItemText = styled.span`
  color: ${({ color }) => (color ? color : ' #2f3036')};
  font-size: 14px;
  line-height: 1.57;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const CustomDropdownMenu = styled(DropdownMenu)`
  max-height: 250px;
  overflow: auto;
  z-index: 2000; /* Temporary solution. New hook will be implemented to solve issues with pagination and querry */
`;

Select.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.object,
  items: PropTypes.array,
  labelKey: PropTypes.string,
  onSelect: PropTypes.func,
  width: PropTypes.string,
};

export default Select;
