import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const PasswordToggle = ({ type, setInputType }) => {
  return (
    <ToggleIconWrapper>
      {type === 'password' && <FaEyeIcon className="text-secondary" onClick={() => setInputType('text')} />}
      {type === 'text' && <FaEyeSlashIcon className="text-secondary" onClick={() => setInputType('password')} />}
    </ToggleIconWrapper>
  );
};
PasswordToggle.propTypes = {
  type: PropTypes.string,
  setInputType: PropTypes.func,
};

const ToggleIconWrapper = styled.span`
  position: absolute;
  right: 1rem;
  top: 33px;
  z-index: 10;
  cursor: pointer;
`;

const FaEyeIcon = styled(FaEye)`
  font-size: 17px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const FaEyeSlashIcon = styled(FaEyeSlash)`
  font-size: 17px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default PasswordToggle;
